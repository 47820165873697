import React from "react";
import { Table, Badge } from 'reactstrap';
import _ from 'lodash';
import Images from "../../assets/images";
import { configs } from '../../utils/configs'

const MultipleAppointmentGrid = ({ appointments, removeAppointmentFromBulk, facilities, services }) => {
    return (
        <Table bordered hover responsive className="mt-2" style={{ fontSize: "0.8rem" }}>
            <thead>
                <tr>
                    <th width="4%"></th>
                    <th width={`${facilities ? '44' : '54'}%`}>Date</th>
                    <th width="19%">Service</th>
                    {facilities && <th width="14%">Facility</th>}
                    <th width="8%">Start</th>
                    <th width="8%">End</th>
                    <th width="4%">Amount</th>
                </tr>
            </thead>
            <tbody>
                {appointments.map((appointment, i) => {
                    let serviceName = _.get(_.find(services, { 'serviceId': appointment.serviceId}), 'serviceName', '');

                    serviceName = serviceName ? serviceName : appointment.serviceName;
                    return (
                        <tr>
                            <td style={{textAlign: "center"}}><img src={Images.deleteIcon} width="15" height="15" style={{cursor: "pointer"}} onClick={() => removeAppointmentFromBulk(i)} /></td>
                            <td>{appointment.appointmentDate}</td>
                            <td>{serviceName} <Badge className="label mr-2" color="info" >{appointment.halfCourtName}</Badge></td>
                            {facilities && <td>{_.get(_.find(facilities, { 'facilityId': appointment.facilityId}), 'facilityName', '--')}</td>}
                            <td>{appointment.startTime}</td>
                            <td>{appointment.endTime}</td>
                            <td className="text-right">{configs.currencyPrefix}{Number.parseFloat(`${appointment.amount}`).toFixed(2)}</td>
                        </tr>
                    )
                })}
                <tr>
                    <td colSpan={3}>Total Amount</td>
                    <td colSpan={4} className="text-right">{configs.currencyPrefix}{Number.parseFloat(`${_.sumBy(appointments, a => Number(a.amount))}`).toFixed(2)}</td>
                </tr>
            </tbody>
        </Table>
    )
}

export default MultipleAppointmentGrid;
