import {
    CONFIG_ADD_APPOINTMENT_MODAL, TOGGLE_ADD_APPOINTMENT_MODAL
} from "../actions/types";

const INITIAL_STATE = {
    addAppointmentModalConfig: {
        isOpen: false,
        firstName: '',
        lastName: '',
        email: '',
        mobileNo: '',
        gender: '',
        isDisabledCustomerfirstName: false,
        isDisabledCustomerlastName: false,
        isDisabledCustomeremail: false,
        isDisabledCustomermobileNo: false,
        isDisabledCustomergender: false,
        
    }
}
export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case TOGGLE_ADD_APPOINTMENT_MODAL:
            const isOpen = !state.addAppointmentModalConfig.isOpen

            return {
                ...state,
                addAppointmentModalConfig: {...INITIAL_STATE.addAppointmentModalConfig, isOpen}
            }
        case CONFIG_ADD_APPOINTMENT_MODAL:
            const configurations = action.payload.configuration

            return {
                ...state,
                addAppointmentModalConfig: {...state.addAppointmentModalConfig, ...configurations}
            }
        default:
            return state
    }
}
