import firebase from "firebase/app";
import "firebase/messaging";
// import { addToken } from './../api/api'
import { notify } from '../utils/common'
import { ADD_APPOINTMENT_SUCCESS, CANCEL_BOOKING_SUCCESS } from "../actions/types";

export const fetchNotifications = (user, dispatch) => {

  let generatedToken;

  // Retrieve Firebase Messaging object.
  const messaging = firebase.messaging()
  Notification.requestPermission()
    .then(function () {
      return messaging.getToken({vapidKey: "BKYn9m9IaQsxr2Tq2tJB8ApV4VwrR9DImdV8Dp6v7uXXUQkDh8fvGTcOuXqv0CPoYKck8sT0xbgR8kdKlBzKTCM"});
    })
    .then(function (token) {
      console.log("token is:", token)
      generatedToken = token;

      // adding the token to the database
      // addToken({ "token": token }, user.uid)

      // handling Foreground cloud messaging
      firebase.messaging().onMessage((message) => {
        let appointment = JSON.parse(message.data.payload)
        let type = message.data.type
        let date = new Date(appointment.date)
        appointment.date = date
        let appointmentId = appointment.id

        switch (type) {
          case 'NEW_APPOINTMENT':
            dispatch({ type: ADD_APPOINTMENT_SUCCESS, payload: { appointment } })
            notify('Appointment added successfully', 'SUCCESS')
            break;

          case 'APPOINTMENT_CANCELED':
            dispatch({ type: CANCEL_BOOKING_SUCCESS, payload: { appointmentId } })
            notify('Booking cancelled successfully!', 'SUCCESS')
            break;
        };

      });

    })
    .catch(function (err) {
      console.log("error Occured", err)
    })

  return generatedToken;
}
