import {CONFIG_ADD_APPOINTMENT_MODAL, TOGGLE_ADD_APPOINTMENT_MODAL} from '../actions/types'

export const toggleAddAppointmentModal = () => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_ADD_APPOINTMENT_MODAL })
    }
}

export const configAddAppointmentModal = (configuration) => {
    return async (dispatch) => {
        dispatch({ type: CONFIG_ADD_APPOINTMENT_MODAL, payload: { configuration } })
    }
}
