// General
export const CLEAR_STORE = 'CLEAR_STORE';

// Shop
export const GET_APPOINTMENTS = 'GET_APPOINTMENTS';
export const GET_APPOINTMENTS_SUCCESS = 'GET_APPOINTMENTS_SUCCESS';
export const GET_APPOINTMENTS_ERROR = 'GET_APPOINTMENTS_ERROR';

export const GET_PAYMENT_PACKAGES_SUCCESS = 'GET_PAYMENT_PACKAGES_SUCCESS';
// New
export const GET_SHOP_DATA_SUCCESS = 'GET_SHOP_DATA_SUCCESS';
export const GET_SERVICES = 'GET_SERVICES';
export const GET_SERVICES_SUCCESS = 'GET_SERVICES_SUCCESS';
export const GET_SERVICES_ERROR = 'GET_SERVICES_ERROR';

export const GET_SERVICE_GROUPS = 'GET_SERVICE_GROUPS';
export const GET_SERVICE_GROUPS_SUCCESS = 'GET_SERVICE_GROUPS_SUCCESS';
export const GET_SERVICE_GROUPS_ERROR = 'GET_SERVICE_GROUPS_ERROR';

export const GET_EMPLOYEES = 'GET_EMPLOYEES';
export const GET_EMPLOYEES_SUCCESS = 'GET_EMPLOYEES_SUCCESS';
export const GET_EMPLOYEES_ERROR = 'GET_EMPLOYEES_ERROR';
export const GET_BRANCHES_SUCCESS = 'GET_BRANCHES_SUCCESS';
export const SELECT_BRANCH = 'SELECT_BRANCH';

export const GET_FACILITIES = 'GET_FACILITIES';
export const GET_FACILITIES_SUCCESS = 'GET_FACILITIES_SUCCESS';
export const GET_FACILITIES_ERROR = 'GET_FACILITIES_ERROR';
export const CREATE_FACILITIES_SUCCESS = 'CREATE_FACILITIES_SUCCESS';
export const UPDATE_FACILITY_SUCCESS = 'UPDATE_FACILITY_SUCCESS';

export const GET_CUSTOMERS = 'GET_CUSTOMERS';
export const GET_CUSTOMERS_SUCCESS = 'GET_CUSTOMERS_SUCCESS';
export const GET_CUSTOMERS_ERROR = 'GET_CUSTOMERS_ERROR';
export const GET_CUSTOMERS_FOR_PAGINATION = 'GET_CUSTOMERS_FOR_PAGINATION';

export const CREATE_EMPLOYEE_SUCCESS = 'CREATE_EMPLOYEE_SUCCESS';

export const CREATE_SERVICE_SUCCESS = 'CREATE_SERVICE_SUCCESS';

export const UPDATE_EMPLOYEE_SUCCESS = 'UPDATE_EMPLOYEE_SUCCESS';

export const UPDATE_CUSTOMER_SUCCESS = 'UPDATE_CUSTOMER_SUCCESS';

export const DELETE_EMPLOYEE_SUCCESS = 'DELETE_EMPLOYEE_SUCCESS';

export const SEARCH_CUST_BY_PHONE = 'SEARCH_CUST_BY_PHONE';
export const SEARCH_CUST_BY_PHONE_SUCCESS = 'SEARCH_CUST_BY_PHONE_SUCCESS';
export const SEARCH_CUST_BY_PHONE_ERROR = 'SEARCH_CUST_BY_PHONE_ERROR';

export const SEARCH_CUST_BY_NAME = 'SEARCH_CUST_BY_NAME';
export const SEARCH_CUST_BY_NAME_SUCCESS = 'SEARCH_CUST_BY_NAME_SUCCESS';
export const SEARCH_CUST_BY_NAME_ERROR = 'SEARCH_CUST_BY_NAME_ERROR';

export const UPDATE_SERVICE_SUCCESS = 'UPDATE_SERVICE_SUCCESS';

export const UPDATE_SHOP = 'UPDATE_SHOP';
export const UPDATE_SHOP_SUCCESS = 'UPDATE_SHOP_SUCCESS';
export const UPDATE_SHOP_ERROR = 'UPDATE_SHOP_ERROR';

export const ADD_APPOINTMENT_SUCCESS = 'ADD_APPOINTMENT_SUCCESS';

export const UPDATE_APPOINTMENT_SUCCESS = 'UPDATE_APPOINTMENT_SUCCESS';

export const ADD_CUSTOMER_SUCCESS = 'ADD_CUSTOMER_SUCCESS';

export const ADD_LEAVES_SUCCESS = 'ADD_LEAVES_SUCCESS';

export const ADD_HOLIDAYS_SUCCESS = 'ADD_HOLIDAYS_SUCCESS';

export const GET_UPCOMING_LEAVES = 'GET_UPCOMING_LEAVES';
export const GET_UPCOMING_LEAVES_SUCCESS = 'GET_UPCOMING_LEAVES_SUCCESS';
export const GET_UPCOMING_LEAVES_ERROR = 'GET_UPCOMING_LEAVES_ERROR';

export const GET_UPCOMING_HOLIDAYS = 'GET_UPCOMING_HOLIDAYS';
export const GET_UPCOMING_HOLIDAYS_SUCCESS = 'GET_UPCOMING_HOLIDAYS_SUCCESS';
export const GET_UPCOMING_HOLIDAYS_ERROR = 'GET_UPCOMING_HOLIDAYS_ERROR';

export const DELETE_LEAVES_SUCCESS = 'DELETE_LEAVES_SUCCESS';
export const CHANGE_LEAVE_PERMISSION = 'CHANGE_LEAVE_PERMISSION';

export const DELETE_HOLIDAYS_SUCCESS = 'DELETE_HOLIDAYS_SUCCESS';

export const GET_UPCOMING_TIME_OFFS = 'GET_UPCOMING_TIME_OFFS';
export const GET_UPCOMING_TIME_OFF_SUCCESS = 'GET_UPCOMING_TIME_OFF_SUCCESS';
export const GET_UPCOMING_TIME_OFF_ERROR = 'GET_UPCOMING_TIME_OFF_ERROR';

export const ADD_TIME_OFF_SUCCESS = 'ADD_TIME_OFF_SUCCESS'
export const UPDATE_TIME_OFF_SUCCESS = 'UPDATE_TIME_OFF_SUCCESS'

export const CANCEL_BOOKING_SUCCESS = 'CANCEL_BOOKING_SUCCESS';

export const GET_TOTAL_FACILITIES_SUCCESS = 'GET_TOTAL_FACILITIES_SUCCESS';
export const GET_TOTAL_EMPLOYEES_SUCCESS = 'GET_TOTAL_EMPLOYEES_SUCCESS';
export const GET_TOTAL_SERVICES_SUCCESS = 'GET_TOTAL_SERVICES_SUCCESS';


// Covid Actions
export const GET_COVID_DATA = 'GET_COVID_DATA';
export const GET_COVID_DATA_SUCCESS = 'GET_COVID_DATA_SUCCESS';
export const GET_COVID_DATA_ERROR = 'GET_COVID_DATA_ERROR';

export const SEARCH_COVID_BY_PHONE = 'SEARCH_COVID_BY_PHONE';
export const SEARCH_COVID_BY_PHONE_SUCCESS = 'SEARCH_COVID_BY_PHONE_SUCCESS';
export const SEARCH_COVID_BY_PHONE_ERROR = 'SEARCH_COVID_BY_PHONE_ERROR';


// User actions
export const FETCH_ADMIN_PROFILE_SUCCESS = 'FETCH_ADMIN_PROFILE_SUCCESS';
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_ERROR = 'UPDATE_USER_ERROR';

export const LOGIN = 'LOGIN';
export const SIGNUP = 'SIGNUP';
export const CHANGE_AUTH_STATE = 'CHANGE_AUTH_STATE';


// Analytics
export const GET_INITIAL_ANALYTICS = 'GET_INITIAL_ANALYTICS';
export const GET_INITIAL_ANALYTICS_SUCCESS = 'GET_INITIAL_ANALYTICS_SUCCESS';
export const GET_INITIAL_ANALYTICS_ERROR = 'GET_INITIAL_ANALYTICS_ERROR';

export const GET_GRAPH_DATA = 'GET_GRAPH_DATA';
export const GET_GRAPH_DATA_SUCCESS = 'GET_GRAPH_DATA_SUCCESS';
export const GET_GRAPH_DATA_ERROR = 'GET_GRAPH_DATA_ERROR';


export const UPDATE_SUB_STATUS = 'UPDATE_SUB_STATUS';

export const GET_DASHBOARD_DATA = 'GET_DASHBOARD_DATA'
export const GET_DASHBOARD_DATA_SUCCESS = 'GET_DASHBOARD_DATA_SUCCESS'
export const GET_DASHBOARD_DATA_ERROR = 'GET_DASHBOARD_DATA_ERROR'

// Branches
export const CREATE_BRANCH = 'CREATE_BRANCH'
export const CREATE_BRANCH_SUCCESS = 'CREATE_BRANCH_SUCCESS'
export const CREATE_BRANCH_ERROR = 'CREATE_BRANCH_ERROR'
export const UPDATE_BRANCH = 'UPDATE_BRANCH'
export const UPDATE_BRANCH_SUCCESS = 'UPDATE_BRANCH_SUCCESS'
export const UPDATE_BRANCH_ERROR = 'UPDATE_BRANCH_ERROR'
export const CHANGE_BRANCH_STATUS_SUCCESS = 'CHANGE_BRANCH_STATUS_SUCCESS'
export const SEARCH_BRANCHES = 'SEARCH_BRANCHES'
export const SEARCH_BRANCHES_SUCCESS = 'SEARCH_BRANCHES_SUCCESS'
export const SEARCH_BRANCHES_ERROR = 'SEARCH_BRANCHES_ERROR'

//Add Appointment Modal
export const TOGGLE_ADD_APPOINTMENT_MODAL = 'TOGGLE_ADD_APPOINTMENT_MODAL'
export const CONFIG_ADD_APPOINTMENT_MODAL = 'CONFIG_ADD_APPOINTMENT_MODAL'
