import { fetchAppointmentsForAnalytics, fetchGraphData, fetchGraphDataForAnalytics, getInitialAnalyticsData } from './../api/api'
import moment from 'moment'
import * as _ from 'lodash';

import {
    GET_INITIAL_ANALYTICS,
    GET_INITIAL_ANALYTICS_SUCCESS,
    GET_INITIAL_ANALYTICS_ERROR,

    GET_GRAPH_DATA,
    GET_GRAPH_DATA_SUCCESS,
    GET_GRAPH_DATA_ERROR
} from '../actions/types'

export const getInitialAnalytics = (todayDate, weekStartDate, weekEndDate, monthStartDate, monthEndDate, year, branchId, shopId) => {
    return async (dispatch) => {

        dispatch({ type: GET_INITIAL_ANALYTICS })

        try {
            let response = await getInitialAnalyticsData(todayDate, weekStartDate, weekEndDate, monthStartDate, monthEndDate, year, branchId, shopId)
            let analyticsData = response.data.data
            dispatch({ type: GET_INITIAL_ANALYTICS_SUCCESS, payload: { analyticsData } })
        } catch (error) {
            console.log(error)
            dispatch({ type: GET_INITIAL_ANALYTICS_ERROR })
        }
    }
}

export const getGraphData = (startDate, endDate, branchId, shopId) => {
    return async (dispatch) => {
        dispatch({ type: GET_GRAPH_DATA })

        try {
            let response = await fetchGraphDataForAnalytics(startDate, endDate, branchId, shopId)
            let graphData = response.data.data
            dispatch({ type: GET_GRAPH_DATA_SUCCESS, payload: { graphData } })
        } catch (error) {
            console.log(error)
            dispatch({ type: GET_GRAPH_DATA_ERROR })
        }
    }
}

// Return appointments filtered as today, this week, this month, this year
const getFilteredAppointments = (appointments) => {

    let thisYear = moment(new Date()).year()
    let thisMonth = moment(new Date()).month()      // Assumption: all fetched appointments are of the same year
    let thisWeek = moment(new Date()).week();    // Assumption: 1) All fetched appointments are of the same year 2) Starting day of week is sunday (In aus)
    let today = moment(new Date()).format('YYYY-MM-DD')

    let thisYearAppointments = []
    let thisMonthAppointments = []
    let thisWeekAppointments = []
    let todayAppointments = []

    _.forEach(appointments, (obj) => {
        let date = obj.date

        let appointmentYear = moment(date).year()
        let appointmentMonth = moment(date).month()
        let appointmentWeek = moment(date).week()
        let appointmentDate = moment(date).format('YYYY-MM-DD')

        if (thisYear === appointmentYear) {
            thisYearAppointments.push(obj)
        }

        if (thisMonth === appointmentMonth) {
            thisMonthAppointments.push(obj)
        }

        if (thisWeek === appointmentWeek) {
            thisWeekAppointments.push(obj)
        }

        if (today === appointmentDate) {
            todayAppointments.push(obj)
        }
    })

    return { thisYearAppointments, thisMonthAppointments, thisWeekAppointments, todayAppointments }
}

const getFilteredAppointmentStatus = (appointments) => {

    let upcomingAppointments = []
    let completedAppointments = []
    let cancelledAppointments = []

    _.forEach(appointments,(appointment) => {

        let appointmentTime = new Date(appointment.startTime)
        let curerntTime = new Date()

        if (appointment.state === 'NEW' && curerntTime < appointmentTime){
            upcomingAppointments.push(appointment)
        }

        if (appointment.state === 'NEW' && curerntTime > appointmentTime){
            completedAppointments.push(appointment)
        }

        if (appointment.state === 'CANCELED'){
            cancelledAppointments.push(appointment)
        }

    })

    return { upcomingAppointments, completedAppointments, cancelledAppointments }
    
}