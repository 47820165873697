import React, { useEffect, useState } from "react";
import { Input, Table, Row, Col } from "reactstrap";
import DatePicker from "reactstrap-date-picker/lib/DatePicker";
import moment from "moment";
import "moment-recur";
import { toast } from "react-toastify";
import { configs } from "../../utils/configs";
import _ from "lodash";

const RecurringAppointmentPanel = ({ appointments, setRecurredAppointmentArray, selectedService, amount, timeOffs, holidays, selectedAppointmentDate, shopOpeningHours }) => {
    const [endDate, setEndDate] = useState({
        value: null,
        formattedValue: null
    });

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [frequency, setFrequency] = useState('Weekly');
    const [recurringAppointment, setRecurringAppointment] = useState([]);
    const [totalAmount, setTotalAmount] = useState(amount);

    const toggle = () => {
        setDropdownOpen(!dropdownOpen)
    }

    const handleChange = (v, f) => {
        setEndDate(
            {
                value: v,
                formattedValue: f
            }
        )
    }

    useEffect(() => {
        let grandTotal = 0;

        if (endDate.value === "" || endDate.value === null) {
            return;
        }

        if (recurringAppointment[0] && recurringAppointment[0].appointmentDate > endDate.value) {
            toast("Invalid date selection", { className: 'toast-custom', type: toast.TYPE["ERROR"] })
            return;
        }

        if (recurringAppointment[0] && recurringAppointment[0].appointmentDate) {
            let duration = 7;
            switch (frequency) {
                case 'Weekly':
                    duration = 7;
                    break;
                case 'Daily':
                    duration = 1;
                    break;
            }

            let recurrence_dates = [];

            if (frequency === 'Monthly') {
                let done = false;
                let day = moment(recurringAppointment[0].appointmentDate, "YYYY-MM-DD").format('D');
                let currantAppointmentDate = moment(recurringAppointment[0].appointmentDate, "YYYY-MM-DD");
                recurrence_dates.push(currantAppointmentDate.format('YYYY-MM-DD'));

                while (!done) {
                    let nextYearMonth = currantAppointmentDate
                        .add(1, 'months')
                        .format('YYYY-MM');

                    let nextMonthDate = moment(nextYearMonth + '-' + day);
                    let ed = moment(endDate.value)

                    // Check if the day is invalid (e.g., February 31st, April 31st)
                    if (!nextMonthDate.isValid()) {
                        nextYearMonth = currantAppointmentDate.add(1, 'months').format('YYYY-MM');
                        nextMonthDate = moment(nextYearMonth + '-' + day);
                    }

                    if (ed.isSameOrAfter(nextMonthDate)) {
                        recurrence_dates.push(nextMonthDate.format("YYYY-MM-DD"));
                    } else {
                        done = true;
                    }
                }

            }
            else {
                let recurrence = moment().recur(moment(recurringAppointment[0].appointmentDate).format('YYYY-MM-DD'), moment(endDate.value).add(1, 'd').format('YYYY-MM-DD')).every(duration).days();
                recurrence_dates = recurrence.all("YYYY-MM-DD");
            }

            console.log("recurrence_dates ", recurrence_dates)

            let newAppointments = recurrence_dates.map((el) => {
                let _rAppointment = {};
                const _rDate = el;

                _rAppointment = {
                    ...recurringAppointment[0],
                    appointmentDate: _rDate
                };

                const calcFullDayAmount = () => {
                    const dayName = moment(new Date(_rAppointment.appointmentDate)).format('dddd')
                    const openingHours = _.find(shopOpeningHours, (a) => { return a.day === dayName })
                    const serviceTime = selectedService.serviceHour * 60 + selectedService.serviceMinute
                    let duration = moment(_rAppointment.endTime, 'HH:mm').diff(moment(_rAppointment.startTime, 'HH:mm'), 'minutes');

                    if (openingHours) {
                        const startTimeFullDay = moment(openingHours.openTime, "h:mm a");
                        const endTimeFullDay = moment(openingHours.closeTime, "h:mm a");

                        _rAppointment.startTime = startTimeFullDay.format('HH:mm')
                        _rAppointment.endTime = endTimeFullDay.format('HH:mm')
                        duration = moment.utc(endTimeFullDay, 'h:mm a').diff(moment.utc(startTimeFullDay, 'h:mm a'), 'minutes');
                    }

                    return (selectedService.fullDayPrice > 0 ? selectedService.fullDayPrice : ((selectedService.serviceFullPrice / serviceTime) * duration))
                }

                if (selectedService.isAdditionalPricingSupport) {

                    let otherPriceArray = [];
                    let otherPriceCharging = 0;

                    otherPriceArray = calculateOtherPriceAmount(_.get(selectedService, 'additionalPricingList', []), _rAppointment.appointmentDate, moment(_rAppointment.startTime, 'HH:mm').format('h:mm a'), moment(_rAppointment.endTime, 'HH:mm').format('h:mm a'), _rAppointment.isFullDay);

                    if (otherPriceArray.length > 0) {
                        otherPriceArray = _.map(otherPriceArray, obj => {
                            const charging = Number.parseFloat(_rAppointment.isFullDay ? obj.meta.fullPrice : (obj.otherTimeMinutes * ((_rAppointment.halfCourtName === "FULL" ? obj.meta.fullPrice : obj.meta.partialPrice) / obj.serviceChargeMinutes)));

                            otherPriceCharging = otherPriceCharging + charging;
                            return { ...obj, charging }
                        })
                    }

                    let total = _rAppointment.isFullDay ? calcFullDayAmount() : _rAppointment.customizedSlots ? (amount * _rAppointment.customizedSlots) : amount

                    total = total + otherPriceCharging;

                    _rAppointment.amount = Number.parseFloat(`${total}`).toFixed(2).toLocaleString();
                    _rAppointment.additionalPricingListIds = _.map(otherPriceArray, obj => obj.meta.id);
                    _rAppointment.additionalPricingList = otherPriceArray;

                    grandTotal += Number.parseFloat(`${total}`);
                } else {
                    _rAppointment.amount = _rAppointment.isFullDay ? calcFullDayAmount() : _rAppointment.customizedSlots ? (amount * _rAppointment.customizedSlots) : amount
                    _rAppointment.additionalPricingListIds = [];
                    _rAppointment.additionalPricingList = [];

                    grandTotal += Number.parseFloat(`${_rAppointment.amount}`);
                }

                return _rAppointment
            })

            setTotalAmount(grandTotal.toFixed(2));

            setRecurringAppointment([...newAppointments]);
            setRecurredAppointmentArray([...newAppointments]);
        }
    }, [endDate.value, frequency]);

    useEffect(() => {
        setRecurringAppointment([appointments[appointments.length - 1]]);
    }, [appointments.length])

    const calculateOtherPriceAmount = (service, appointmentDate, startTime, endTime, isFullDay) => {
        const additionalPricing = _.filter(service, {day: moment(appointmentDate).format('dddd'), status: 'Active'});
        const otherPriceArray = [];

        if (additionalPricing.length > 0) {
          _.forEach(additionalPricing, function(obj) {
            const otherPriceObject = {
              serviceChargeMinutes: 0,
              otherTimeMinutes: 0,
              meta: {}
            }
            if (isFullDay) {
              otherPriceObject.otherTimeMinutes = moment(obj.endTime, 'HH:mm').diff(moment(obj.startTime, 'HH:mm'), 'minutes');
              otherPriceObject.meta = {...obj};
              otherPriceObject.serviceChargeMinutes = otherPriceObject.otherTimeMinutes;

              otherPriceArray.push(otherPriceObject);
            } else {
              const selectedTimeSlotStartTime = startTime;
              const selectedTimeSlotEndTime = endTime;
              const otherPriceStartTime = obj.startTime;
              const otherPriceEndTime = obj.endTime;
              const additionalPriceStartTime = moment(selectedTimeSlotStartTime, 'h:mm a').isBetween(moment(otherPriceStartTime, 'HH:mm'), moment(otherPriceEndTime, 'HH:mm'), 'minutes', '[]');
              const additionalPriceEndTime = moment(selectedTimeSlotEndTime, 'h:mm a').isBetween(moment(otherPriceStartTime, 'HH:mm'), moment(otherPriceEndTime, 'HH:mm'), 'minutes', '[]');
              const selectedTimeStart = moment(otherPriceStartTime, 'HH:mm').isBetween(moment(selectedTimeSlotStartTime, 'h:mm a'), moment(selectedTimeSlotEndTime, 'h:mm a'), 'minutes', '[]');
              const selectedTimeEnd = moment(otherPriceEndTime, 'HH:mm').isBetween(moment(selectedTimeSlotStartTime, 'h:mm a'), moment(selectedTimeSlotEndTime, 'h:mm a'), 'minutes', '[]');

              if (additionalPriceEndTime && additionalPriceStartTime) {
                otherPriceObject.otherTimeMinutes = moment(selectedTimeSlotEndTime, 'h:mm a').diff(moment(selectedTimeSlotStartTime, 'h:mm a'), 'minutes');
              }else if (additionalPriceEndTime) {
                otherPriceObject.otherTimeMinutes = moment(selectedTimeSlotEndTime, 'h:mm a').diff(moment(otherPriceStartTime, 'HH:mm'), 'minutes');
              } else if (additionalPriceStartTime) {
                otherPriceObject.otherTimeMinutes = moment(otherPriceEndTime, 'HH:mm').diff(moment(selectedTimeSlotStartTime, 'h:mm a'), 'minutes');
              } else if (selectedTimeStart && selectedTimeEnd) {
                otherPriceObject.otherTimeMinutes = moment(otherPriceEndTime, 'HH:mm').diff(moment(otherPriceStartTime, 'HH:mm'), 'minutes');
              }

              if (otherPriceObject.otherTimeMinutes) {
                otherPriceObject.serviceChargeMinutes = moment(obj.endTime, 'HH:mm').diff(moment(obj.startTime, 'HH:mm'), 'minutes');
                otherPriceObject.meta = {...obj};
                otherPriceArray.push(otherPriceObject);
              }
            }
          });

          return otherPriceArray;
        }

        return otherPriceArray;
      }

    const isAppointmentDisable = (appointment) => {
        const appointmentDateWithStartTime = moment(`${appointment.appointmentDate} ${appointment.startTime}`, 'YYYY-MM-DD HH:mm');
        const appointmentDateWithEndTime = moment(`${appointment.appointmentDate} ${appointment.endTime}`, 'YYYY-MM-DD HH:mm');

        let isDisabled = false;

        timeOffs.forEach((leave) => {
            const leveDate = moment(leave.leaveDate || leave.facilityOffDate).format('YYYY-MM-DD');


            const leaveStartDate = moment(`${leveDate} ${leave.startTime}`, 'YYYY-MM-DD HH:mm');
            const leaveEndDate = moment(`${leveDate} ${leave.endTime}`, 'YYYY-MM-DD HH:mm');

            if  (appointmentDateWithStartTime.isBetween(leaveStartDate, leaveEndDate, 'minutes', '[]') || appointmentDateWithEndTime.isBetween(leaveStartDate, leaveEndDate, 'minutes', '[]')) {
                isDisabled = true;
            }
        });

        holidays.forEach((holiday) => {
            const date = moment(holiday.holiday).format('YYYY-MM-DD');
            const leaveStartDate = moment(`${date} ${holiday.startTime}`, 'YYYY-MM-DD HH:mm');
            const leaveEndDate = moment(`${date} ${holiday.endTime}`, 'YYYY-MM-DD HH:mm');

            if  (appointmentDateWithStartTime.isBetween(leaveStartDate, leaveEndDate, 'minutes', '[]') || appointmentDateWithEndTime.isBetween(leaveStartDate, leaveEndDate, 'minutes', '[]')) {
                isDisabled = true;
            }
        });

        return isDisabled;
    };

    return (
        <>
            <hr />
            <div style={{ padding: 2 }}>
                <Row>
                    <Col xs="4">
                        Frequency
                    </Col>
                    <Col>
                        <Input type="select" value={frequency} onChange={(e) => setFrequency(e.target.value)}>
                            <option value="Weekly">Weekly</option>
                            <option value="Monthly">Monthly</option>
                        </Input>
                    </Col>
                </Row>
                <hr />
                <Row>
                    <Col xs="4">
                        End Date
                    </Col>
                    <Col>
                        <DatePicker id="recurance-end"
                            minDate={frequency === 'Monthly' ? moment(new Date(selectedAppointmentDate)).add(1, 'M').toISOString() : moment(new Date(selectedAppointmentDate)).add(1, 'w').toISOString()}
                            value={endDate.value}
                            onChange={(v, f) => handleChange(v, f)}
                            autoFocus={true}
                            autoComplete="off"
                        />
                    </Col>
                </Row>
            </div>
            <Row>
                <Table bordered hover responsive className="mt-2" style={{ fontSize: "0.8rem" }}>
                    <tbody>
                        {recurringAppointment.map((ap, key) => <tr key={key} className={`${isAppointmentDisable(ap) ? 'an_disable_booking_slot' : ''}`}>
                            <td>{ap.appointmentDate}</td>
                            <td>{_.get(selectedService, 'serviceName', '')}</td>
                            <td>{ap.startTime}</td>
                            <td>{ap.endTime}</td>
                            <td className="text-right">
                                <div>{configs.currencyPrefix} {ap.amount}</div>
                            </td>
                        </tr>)}
                        <tr>
                            <td colSpan={4}>Total</td>
                            <td className="text-right">
                                <div>{configs.currencyPrefix} {Number.parseFloat(`${_.sumBy(recurringAppointment, a => Number(a.amount))}`).toFixed(2)}</div>
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </Row>
        </>
    );
}

export default RecurringAppointmentPanel;
