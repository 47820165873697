import React from 'react';
import { Link } from 'react-router-dom';

const NotFoundView = () => (
  <div className= "text-center" style={{marginTop:"240px"}}>
    <h1>404 - Not Found!</h1>
    <Link to="/">
     <h5> Navigate to Dashboard</h5>
    </Link>
  </div>
);

export default NotFoundView;