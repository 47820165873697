import {
    CLEAR_STORE,

    GET_COVID_DATA,
    GET_COVID_DATA_SUCCESS,
    GET_COVID_DATA_ERROR,

    SEARCH_COVID_BY_PHONE,
    SEARCH_COVID_BY_PHONE_SUCCESS,
    SEARCH_COVID_BY_PHONE_ERROR
} from "../actions/types";


const INITIAL_STATE = {
    covidData: [],

    // Loading states
    isFetchingCovidData: false
}


export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CLEAR_STORE:
            return {
                ...INITIAL_STATE
            }
        case GET_COVID_DATA:
            return {
                ...state,
                isFetchingCovidData: true
            }
        case GET_COVID_DATA_SUCCESS:
            return {
                ...state,
                covidData: action.payload.covidData,
                isFetchingCovidData: false
            }
        case GET_COVID_DATA_ERROR:
            return {
                ...state,
                isFetchingCovidData: false
            }
        case SEARCH_COVID_BY_PHONE:
            return {
                ...state,
                isFetchingCovidData: true
            }
        case SEARCH_COVID_BY_PHONE_SUCCESS:
            return {
                ...state,
                covidData: action.payload.covidData,
                isFetchingCovidData: false
            }
        case SEARCH_COVID_BY_PHONE_ERROR:
            return {
                ...state,
                isFetchingCovidData: false
            }
        default:
            return state
    }
}
