import {
    CLEAR_STORE,
    LOGIN,
    SIGNUP,
    CHANGE_AUTH_STATE
} from "../actions/types";

const INITIAL_STATE = {
    isLoggedIn: false,

    branchId: null,
    subscription: null,
    isSubscribed: false,
    stripeId: '',
    role: "",
    shopId: null,
    token: '',
    userId: null,
    shopData: [],

    isUpdatingUser: false
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CLEAR_STORE:
            return {
                ...INITIAL_STATE
            }
        case LOGIN: 
            return {
                ...state,
                ...action.payload.user
            }  
        case CHANGE_AUTH_STATE:
            return {
                ...state,
                isLoggedIn: action.payload.isLoggedIn
            }  
        case SIGNUP:
            return {
                ...state,
                shopData: action.payload.shopData
            }    
        default:
            return {
                ...state
            } 
    }
}
