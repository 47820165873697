import firebase from "firebase/app";
import "firebase/storage";
import { configs } from '../utils/configs'

const projectId = configs.firebase.projectId

export const uploadToStorage = async (imageFile, mainFolder, shopSlug, itemId, suffix = "") => {
    // compress image
    let compressedFile = await compressImages(imageFile);

    // get image type
    let arr = imageFile.name.split(".")
    let mime = arr[arr.length - 1]

    // Create a reference to 'mainFolder/shopSlug/imageNamesuffix.jpg'  suffix is needed to tell updated time
    let imageRef = `${mainFolder}/${shopSlug}/${itemId}${suffix}.${mime}`
    let newImageRefObject = firebase.storage().ref().child(imageRef);

    await newImageRefObject.put(compressedFile)

    let url = `https://firebasestorage.googleapis.com/v0/b/${projectId}.appspot.com/o/${mainFolder}%2F${shopSlug}%2F${itemId}${suffix}.${mime}?alt=media`
    return { url, imageRef }
}

export const deleteFromStorage = async (imageRef) => {
    try {
        let imageRefObject = firebase.storage().ref().child(imageRef);

        await imageRefObject.delete();

        return {success: true, message: "File deleted successfully"}
    } catch (error) {
        console.error("Image not found to delete");

        return {success: false, message: "Failed to delete file"}
    }
}

export const extractImageRefFromUrl = (url) => {
    const decodedUrl = decodeURIComponent(url);

    const startIndex = decodedUrl.indexOf("/o/") + 3;
    const endIndex = decodedUrl.indexOf("?");

    const extractedRef = decodedUrl.substring(startIndex, endIndex);

    return extractedRef;
}

function compressImages(imgFile) {
    const MAX_WIDTH = 900;
    const MAX_QUALITY = 0.7 // 0 to 1

    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(imgFile);
        reader.onload = event => {
            try {

                const img = new Image();
                img.src = event.target.result;
                img.onload = () => {
                    try {

                        let newWidth = img.width > MAX_WIDTH ? MAX_WIDTH : img.width;
                        let newHeight = Math.floor(img.height / img.width * newWidth);

                        const elem = document.createElement('canvas');
                        elem.width = newWidth;
                        elem.height = newHeight;

                        const ctx = elem.getContext('2d');
                        ctx.drawImage(img, 0, 0, newWidth, newHeight);

                        let newDataUrl = ctx.canvas.toDataURL("image/jpeg", MAX_QUALITY);

                        urltoFile(newDataUrl, "compressed-file", "image/jpeg").then((result) => {
                            resolve(result)
                        }).catch(err => {
                            console.log(err);
                            reject(err);
                        })
                    } catch (error) {
                        console.log(error);
                        reject(error);
                    }
                }
            } catch (error) {
                console.log(error);
                reject(error);
            }
        }
        reader.onerror = error => {
            console.log(error);
            reject(error);
        };

    })
}

function urltoFile(url, filename, mimeType) {
    mimeType = mimeType || (url.match(/^data:([^;]+);/) || '')[1];
    return (fetch(url)
        .then(function (res) { return res.arrayBuffer(); })
        .then(function (buf) { return new Blob([buf], { type: mimeType }); })
    );
}