import React, { Component } from 'react'
import {
    Button, Spinner,
    Modal, ModalHeader, ModalBody, ModalFooter,
    Form, FormGroup, Label, Input, Col, Row,
    UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, FormFeedback, FormText,
    Table
} from 'reactstrap';
import * as _ from 'lodash';
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import TextareaAutosize from 'react-textarea-autosize';
import moment from 'moment'

import { notify } from '../../utils/common'
import { DEFAULT_PROFILE_IMAGE } from '../../utils/constants'
import { ReactComponent as PlusIcon } from "../../assets/images/plus.svg"
import { businessTypes } from "../../utils/configs"
import { configs } from '../../utils/configs'
import { checkPhoneNumber } from '../../utils/common'
import './addAppointmentModal.css'
import MultipleAppointmentGrid from '../AppointmetTypes/MultipleAppointmentGrid';
import RecurringAppointmentPanel from '../AppointmetTypes/RecurringAppointmentPanel';
import InfoTooltip from '../InfoTooltip/InfoTooltip';
import { toast } from "react-toastify";
import { getAppointments, confirmAppointment } from '../../actions';
import { connect } from "react-redux";
import confirm from "reactstrap-confirm";
import Images from "../../assets/images"
import WarningModal  from '../WarningModal/WarningModal';
import { toggleAddAppointmentModal } from '../../actions/BaseComponentsActions';
import {fetchCustomers} from "../../api/api";

// TODO: Update TIME_SLOT_SIZE to 30min per RCTC requirement; make configurable later.
const TIME_SLOT_SIZE = 30; // minutes
const WEEK_MAP = { Sunday: 0, Monday: 1, Tuesday: 2, Wednesday: 3, Thursday: 4, Friday: 5, Saturday: 6 }
const enumAppointment = {
    SINGLE: "SINGLE",
    MULTIPLE: "MULTIPLE",
    RECURRING: "RECURRING"
}
const DEFAULT_MODAL_STATE = {
    isModalOpen: false,
    isAddingAppointment: false,
    isError: false,
    errorMessage: '',
    isValid: true,
    modalType: 'NEW',
    isWarningModalOpen: false,

    // Customer
    firstName: '',
    lastName: '',
    mobileNo: '',
    email: '',
    emailError: '',
    firstNameError: '',
    lastNameError: '',

    // Guest
    guestFullNameError: '',
    guestNICError: '',
    guestFullName: '',
    guestNIC: '',
    isGuestFullNameValid: false,
    isGuestNICValid: false,

    //Service - id, name, serviceTime
    service: {},

    // Facility - id, name
    facility: {},
    facilities: [],  // Facilities of the selected service

    appointmentDate: null,
    startTime: null,
    endTime: null,

    timeSlots: [],
    allTimeSlots: null,
    selectedTimeSlot: null,
    disabledCalendarDates: null,
    selectedTimeSlotStartTime: null,
    selectedTimeSlotEndTime: null,
    endTimeSlots: null,
    noOfSlots: null,

    specialNotes: '',
    amount: null,
    amountInSports: 0,
    halfCourtName: 'FULL',
    isPartial: false,

    selectedBookingArea: null,
    availableFacilityAreaList: [],
    defaultFacilityAreaList: ['FULL', 'HALF_1', 'HALF_2'],
    currentPartialSupportServiceValue: null,
    currentPartialSupportFacilityValue: null,
    displayFacilityAreaList: [],
    displayBookingArea: null,
    currentCourtAreaList: [],
    appointmentsArray: [],
    appointmentType: enumAppointment.MULTIPLE,
    recurredAppointmentArray: [],
    recurringMoment: false,
    isFullDayBooking: false,
    canBookFullDay: true,
    editAnotherDate: false,
    shopOpeningHours: {},
    isAddGuestsModalOpen: false,
    isShowAddGuestForm: false,
    guests: [],
    recurringSelectedDate: null,
}

let updatedData = {}

export class AddAppointmentForFacilityModal extends Component {

    constructor(props) {
        super(props)

        this.state = DEFAULT_MODAL_STATE;
        // this.state.appointmentsArray = [];
        // this.state.appointmentType = enumAppointment.SINGLE;
        // this.state.recurredAppointmentArray = [];
    }

    componentDidMount() {
        let offSet = moment().tz(this.props.timeZone).format('Z');
        this.setState({ offSet: offSet })

        if (this.props.edit && this.props.selectedAppointment.isFullDay) {
            this.setState({isFullDayBooking: this.props.selectedAppointment.isFullDay})
        }

        if (this.props.edit && this.props.selectedAppointment.guests?.length > 0) {
            this.setState({guests: this.props.selectedAppointment.guests})
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.isHandleByRedux && !prevProps.addAppointmentModalConfig.isOpen && this.props.addAppointmentModalConfig.isOpen) {
            const config = {};
            _.forEach(this.props.addAppointmentModalConfig, (val, key) => {
                if (key !== 'isOpen') {
                    config[key] = val
                }
            });

            if (Object.keys(config).length > 0) {
                this.setState(config)
            }
        }
    }

    // Construct time slotesF
    buildtimeSlots = async (interval, facilityId, selectedDate, bookedSlots, ot, ct, serviceTime) => {

        // convert to moment objects
       let openTime = moment(ot, 'HH:mm');
        let closeTime = moment(ct, 'HH:mm');
        let lastTime = closeTime.clone().subtract(serviceTime, 'minutes');

        let timeSlots = [];
        let timeOff = []; // holds holidays/leaves time slots

        let aTime = openTime.clone();

        let leaves = [];
        let holidays = [];
        let expired = [];

        // Get leaves (if any) for the selected date. This is for when the selected facility is closed on a special time or date.
        _.map(this.props.timeOffs, (obj) => {
            let dateA = moment(obj.facilityOffDate).format('YYYY-MM-DD');
            let dateB = moment(selectedDate).format('YYYY-MM-DD');

            if (facilityId === obj.facilityId && dateA === dateB) {
                if (!_.isEmpty(obj.startTime)) {
                    leaves.push(obj);
                }
            }
        });

        // gets shop holidays of the selected date
        _.map(this.props.holidays, (obj) => {
            let dateA = moment(obj.holiday).format('YYYY-MM-DD');
            let dateB = moment(selectedDate).format('YYYY-MM-DD');

            if (dateA === dateB) {
                if (!_.isEmpty(obj.startTime)) {
                    holidays.push(obj);
                }
            }
        });

        while (aTime.isSameOrBefore(lastTime)) {
            let TS = aTime.format('h:mm a');
            let TE = moment(aTime).add(interval, 'minutes').format('h:mm a');

            const checkBookedSlots = () => {
                const currentBucketAppointments = this.state.appointmentsArray.filter((appointment) => {
                    return moment(appointment.appointmentDate).isSame(selectedDate, 'day') && this.state.facility.facilityId === appointment.facilityId;
                });

                if (_.isEmpty(bookedSlots) && _.isEmpty(currentBucketAppointments)) {
                    timeSlots.push(aTime.format('h:mm a'));
                } else {
                    let isClashing = false;
                    const partialSlots = [];

                    _.forEach(bookedSlots, (obj) => {
                        const AS = moment(obj.startTime, 'h:mm a').format('h:mm a');
                        const AE = moment(obj.endTime, 'h:mm a').format('h:mm a');
                        const TEE = moment(`Sun Nov 14 2021 ${TS}:00 GMT${this.state.offSet}`, 'ddd MMM DD YYYY h:mm a ZZ').add(this.state.service.serviceHour * 60 + this.state.service.serviceMinute, 'minutes').format('h:mm a');

                        const clashing = this.timeSlotsClash(TS, TEE, AS, AE, obj.id);

                        if (clashing) {
                            if (obj.partial) {
                                partialSlots.push(aTime.format('h:mm a'));
                            } else {
                                isClashing = true;
                            }

                            if (partialSlots.length > 1) {
                                isClashing = true;
                                return false;
                            }
                        }
                    });

                    if (!isClashing) {
                        _.forEach(currentBucketAppointments, (appoint) => {
                            const AS = moment(`${appoint.appointmentDate} ${appoint.startTime}`, 'YYYY-MM-DD h:mm a').format('h:mm a');
                            const AE = moment(`${appoint.appointmentDate} ${appoint.endTime}`, 'YYYY-MM-DD h:mm a').format('h:mm a');
                            const TEE = moment(`Sun Nov 14 2021 ${TE}:00 GMT${this.state.offSet}`, 'ddd MMM DD YYYY h:mm a ZZ').add(this.state.service.serviceHour * 60 + this.state.service.serviceMinute, 'minutes').format('h:mm a');

                            const clashing = this.timeSlotsClash(TS, TEE, AS, AE, appoint.id);

                            if (clashing) {
                                if (appoint.isPartial) {
                                    partialSlots.push(aTime.format('h:mm a'));
                                } else {
                                    isClashing = true;
                                }

                                if (partialSlots.length > 1) {
                                    isClashing = true;
                                    return false;
                                }
                            }
                        });
                    }

                    this.getBookedAppointmentCount(selectedDate);

                    if (!isClashing && partialSlots.length < 2) {
                        timeSlots.push(aTime.format('h:mm a'));
                    } else {
                        console.log("Clash (booked)", aTime.format('h:mm a'));
                    }
                }
            };

            const filterTimeSlots = () => {
                timeSlots = timeSlots.filter(val => !timeOff.includes(val));
                const uniqueSet = new Set(timeSlots);
                timeSlots = [...uniqueSet];
            };

            if (this.isTimeSlotExpired(selectedDate, TS)) {
                expired.push(TS);
            } else {
                if (!_.isEmpty(leaves) && !_.isEmpty(holidays)) {
                    _.map(leaves, (leave) => {
                        let LS = moment(`Sun Nov 14 2021 ${leave.startTime}:00 GMT${this.state.offSet}`, 'ddd MMM DD YYYY h:mm a ZZ').format('h:mm a');
                        let LE = moment(`Sun Nov 14 2021 ${leave.endTime}:00 GMT${this.state.offSet}`, 'ddd MMM DD YYYY h:mm a ZZ').format('h:mm a');

                        _.map(holidays, (holiday) => {
                            let HS = moment(`Sun Nov 14 2021 ${holiday.startTime}:00 GMT${this.state.offSet}`, 'ddd MMM DD YYYY h:mm a ZZ').format('h:mm a');
                            let HE = moment(`Sun Nov 14 2021 ${holiday.endTime}:00 GMT${this.state.offSet}`, 'ddd MMM DD YYYY h:mm a ZZ').format('h:mm a');

                            if (!this.timeSlotsClash(TS, TE, LS, LE) && !this.timeSlotsClash(TS, TE, HS, HE)) {
                                checkBookedSlots();
                            } else {
                                console.log("Clash (leave & holiday)", aTime.format('h:mm a'));
                                timeOff.push(aTime.format('h:mm a'));
                            }
                        });
                    });
                    filterTimeSlots();
                } else if (!_.isEmpty(holidays)) {
                    _.map(holidays, (holiday) => {
                        let HS = moment(`Sun Nov 14 2021 ${holiday.startTime}:00 GMT${this.state.offSet}`, 'ddd MMM DD YYYY h:mm a ZZ').format('h:mm a');
                        let HE = moment(`Sun Nov 14 2021 ${holiday.endTime}:00 GMT${this.state.offSet}`, 'ddd MMM DD YYYY h:mm a ZZ').format('h:mm a');

                        if (!this.timeSlotsClash(TS, TE, HS, HE)) {
                            checkBookedSlots();
                        } else {
                            console.log("Clash (holiday)", aTime.format('h:mm a'));
                            timeOff.push(aTime.format('h:mm a'));
                        }
                    });
                    filterTimeSlots();
                } else if (!_.isEmpty(leaves)) {
                    _.map(leaves, (leave) => {
                        let LS = moment(`Sun Nov 14 2021 ${leave.startTime}:00 GMT${this.state.offSet}`, 'ddd MMM DD YYYY h:mm a ZZ').format('h:mm a');
                        let LE = moment(`Sun Nov 14 2021 ${leave.endTime}:00 GMT${this.state.offSet}`, 'ddd MMM DD YYYY h:mm a ZZ').format('h:mm a');

                        const TEE = moment(`Sun Nov 14 2021 ${TS}:00 GMT${this.state.offSet}`, 'ddd MMM DD YYYY h:mm a ZZ').add(this.state.service.serviceHour * 60 + this.state.service.serviceMinute, 'minutes').format('h:mm a');

                        if (!this.timeSlotsClash(TS, TEE, LS, LE)) {
                            checkBookedSlots();
                        } else {
                            console.log("Clash (leave)", aTime.format('h:mm a'));
                            timeOff.push(aTime.format('h:mm a'));
                        }
                    });
                    filterTimeSlots();
                } else {
                    checkBookedSlots();
                }
            }

            aTime = moment(aTime).add(interval, 'minutes');
        }

        //Remove Duplicates
        let uniqueTimeSlots = [...new Set(timeSlots)]
        this.setState({ timeSlots: uniqueTimeSlots })

        // For sports only
        if(this.props.businessTypeId === 3) {
            let {allTimeSlots, startTimeSlots, endTimeSlots} = this.getAllTimeSlots(interval, ot, ct, serviceTime)
            let filteredStartTime = allTimeSlots.filter((value) => uniqueTimeSlots.includes(value))[0]

            this.handleOnClickStartTime(filteredStartTime, startTimeSlots, endTimeSlots, uniqueTimeSlots, selectedDate)

            this.setState({canBookFullDay: leaves.length === 0 && holidays.length === 0 && _.isEmpty(bookedSlots) && expired.length === 0})
        }
    }

    toggleModal = async (cancel) => {

        if (_.isEmpty(this.props.services) && !cancel) {

            this.setState((prevState) => {
                return { isWarningModalOpen: !prevState.isWarningModalOpen }
            })

        }
        else if (this.props.edit && !cancel) {

            // let {firstName, lastName, phone, email} = this.props.selectedAppointment.customer
            let { firstName, lastName, email, mobileNo, appointmentDate, startTime, endTime, id, specialNote, fee } = this.props.selectedAppointment
            let serviceId = this.props.selectedAppointment.serviceId
            let facilityId = this.props.selectedAppointment.facilityId
            let isFullDayBooking = this.props.selectedAppointment.isFullDay
            let guests = this.props.selectedAppointment.guests

            let specialNotes = specialNote
            let date = new Date(appointmentDate)
            let service = _.find(this.props.services, (a) => { return a.serviceId === serviceId })
            let facility = _.find(this.props.facilities, (a) => { return a.facilityId === facilityId })
            this.props.selectedAppointment.service = service
            this.props.selectedAppointment.facility = facility

            await this.setState({ id, firstName, lastName, mobileNo, email, startTime, endTime, specialNotes, modalType: 'UPDATE', service, originalData: this.props.selectedAppointment, amount: fee })
            this.setState((prevState) => {
                return { isModalOpen: !prevState.isModalOpen }
            })
            await this.setState({ phoneError: '' })

            //Call select service function
            this.onSelectService(this.state.service)

            //Set facility
            this.onSelectEmployee(facility)
            await this.setState({ appointmentDate: date, facility })


            //Call select date function
            this.onSelectDate(date, {})

            //Set selected time slot
            let appointmentStartTime = moment(`Sun nov 14 2021 ${this.state.startTime}:00 GMT${this.state.offSet}`).format('h:mm a')
            let appointmentEndTime = moment(`Sun nov 14 2021 ${this.state.endTime}:00 GMT${this.state.offSet}`).format('h:mm a')
            await this.setState({ selectedTimeSlot: appointmentStartTime, selectedTimeSlotStartTime: appointmentStartTime, selectedTimeSlotEndTime: appointmentEndTime })

            await this.setState({ selectedBookingArea: this.props.selectedAppointment.courtName, halfCourtName: this.props.selectedAppointment.courtName })

            this.onSelectTimePriceCalculation()
            this.checkChanges('courtName')

            if (this.props.selectedAppointment.courtName === 'FULL') {
                await this.setState({ displayBookingArea: 'Full', selectedBookingArea: 'FULL', isPartial: false })
            } else {
                if (this.state.availableFacilityAreaList.length === 1) {
                    await this.setState({ displayBookingArea: 'Half', selectedBookingArea: 'HALF_2', halfCourtName: 'HALF_2', isPartial: true })
                } else {
                    await this.setState({ displayBookingArea: 'Half', selectedBookingArea: 'HALF_1', halfCourtName: 'HALF_1', isPartial: true })
                }
            }

            if(this.props.businessTypeId === 3) {
                await this.setState({isFullDayBooking, guests});
                this.setEndTimeSlotsArray(appointmentStartTime, this.state.timeSlots, appointmentDate);
            }

            updatedData = {} //As a fail safe

        } else {
            const defaultState = {
                ...DEFAULT_MODAL_STATE,
                appointmentsArray: [],
                appointmentType: enumAppointment.MULTIPLE,
                recurredAppointmentArray: [],
            }
            this.setState((prevState) => {
                return { ...defaultState, isModalOpen: !prevState.isModalOpen }
            })
            this.setState({ phoneError: '' })

            updatedData = {} //As a fail safe
        }
    }

    handleModalClose = () => {
        this.setState((prevState) => {
            return { isWarningModalOpen: !prevState.isWarningModalOpen }
        })
    }

    checkChanges = (field) => {
        if (this.state.modalType === 'UPDATE') {
            if (field === 'firstName' || field === 'lastName' || field === 'mobileNo' || field === 'email') {
                let originalValue = this.state.originalData[field]

                if (originalValue !== this.state[field]) {
                    updatedData = this.state.originalData
                    updatedData[field] = this.state[field]
                    // let fullName = this.state.firstName +' '+ this.state.lastName
                    // updatedData.customer.name = fullName

                }
                else {
                    delete updatedData['customer']
                }
            }

            if (field === 'specialNote') {
                let originalValue = this.state.originalData[field]

                if (originalValue !== this.state[field]) {
                    updatedData[field] = this.state[field]
                }
                else {
                    delete updatedData[field]
                }
            }
            if (field === 'appointmentDate') {
                let originalValue = new Date(this.state.originalData[field])

                if (originalValue !== new Date(this.state[field])) {
                    updatedData[field] = new Date(this.state[field])
                }
                else {
                    delete updatedData[field]
                }
            }

            if (field === 'service') {
                let originalValue = this.state.originalData[field]

                if (originalValue.serviceId !== this.state[field].serviceId) {
                    updatedData[field] = this.state[field]
                }
                else {
                    delete updatedData[field]
                }
            }

            if (field === 'facility') {
                let originalValue = this.state.originalData[field]

                if (originalValue.facilityId !== this.state[field].facilityId) {
                    updatedData[field] = this.state[field]
                }
                else {
                    delete updatedData[field]
                }
            }

            if (field === 'selectedTimeSlot') {
                let originalValue = moment(this.state.originalData.startTime).format('h:mm a')

                // Fix times
                let dateTimeString = `${moment(this.state.appointmentDate).format('YYYY-MM-DD')} ${this.state.selectedTimeSlot}`
                let startTime = moment(dateTimeString)
                let serviceTime = (this.state.service.serviceHour * 60) + this.state.service.serviceMinute
                let endTime = moment(dateTimeString).add(serviceTime, 'minutes')

                if (originalValue !== startTime.format('h:mm a')) {

                    updatedData['startTime'] = startTime.toString()
                    updatedData['endTime'] = endTime.toString()
                }
                else {
                    delete updatedData['startTime']
                    delete updatedData['endTime']
                }
            }

            if (field === 'courtName') {
                let originalValue = this.state.originalData[field]
                if (originalValue !== this.state.selectedBookingArea) {
                    updatedData[field] = this.state.selectedBookingArea
                }
                else {
                    delete updatedData[field]
                }
            }

            if (_.isEmpty(updatedData)) {
                this.setState({ hasUpdates: false })
            } else {
                this.setState({ hasUpdates: true })
            }
        }
    }

    checkPhoneValidity = (text) => {
        var isValid = checkPhoneNumber(text);
        let phoneError = isValid ? false : "Not a valid phone number"
        this.setState({ mobileNo: text, phoneError }, () => this.checkChanges('mobileNo'))
        return isValid
    }

    checkFirstNameValidity = (text) => {
        var regex = new RegExp("^[a-zA-Z ]{3,40}/*$");
        var isValid = regex.test(text);
        let firstNameError = isValid ? false : "Please insert a valid name"
        this.setState({ firstName: text, firstNameError }, () => this.checkChanges('firstName'))
        return isValid
    }

    checkLastNameValidity = (text) => {
        const regex = new RegExp("^[a-zA-Z ]{3,40}/*$");
        const isValid = regex.test(text);
        let lastNameError = isValid ? false : "Please insert a valid name"
        this.setState({ lastName: text, lastNameError }, () => this.checkChanges('lastName'))
        return isValid
    }

    checkEmailValidity = (text) => {
        if (text.trim() === '') {
            this.setState({ email: text, emailError: '' }, () => this.checkChanges('email'))
            return true
        }
        // validate
        var regex = new RegExp("^[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?$");
        var isValid = regex.test(text);

        let emailError = isValid ? false : "Not a valid email address"
        this.setState({ email: text, emailError }, () => this.checkChanges('email'))
        return isValid
    }

    checkButtonDisability = () => {
        if (this.state.isAddingAppointment === true || this.state.firstNameError != '' || this.state.phoneError != '' || this.isValidNextButtonDisabled()) {
            return true;
        }
    }

    checkUpdateButtonDisability = () => {
        return this.state.isAddingAppointment === true || this.state.firstNameError != '' || this.state.phoneError != '' || !this.state.facility?.facilityId || _.isNull(this.state.appointmentDate) || this.isValidNextButtonDisabled() || (this.isBookingAreaEnabled() && _.isEmpty(this.state.selectedBookingArea));
    }

    isValid = () => {
        this.checkPhoneValidity(this.state.mobileNo)
        this.checkFirstNameValidity(this.state.firstName)
        let valid = true;

        if (this.state.firstName === '' || this.state.firstNameError) {
            valid = false
        } else if (this.state.mobileNo === '') {
            valid = false
        } else if (this.state.emailError) {
            valid = false
        } else if (this.isValidNextButtonDisabled()) {
            valid = false
        } else if ((this.isBookingAreaEnabled() && _.isEmpty(this.state.selectedBookingArea))) {
            valid = false
        }
        else if (_.isEmpty(this.state.service)) {
            valid = false
        }
        else if (_.isEmpty(this.state.facility)) {
            valid = false
        }
        else if (_.isNull(this.state.appointmentDate)) {
            valid = false
        }
        else if (_.isNull(this.state.selectedTimeSlot)) {
            valid = false
        } else if (!this.state.selectedTimeSlot && this.props.businessTypeId === 3) {
            valid = false
        }

        // Removed the following validation as the partial support isn't available on the UI
        /* else if ((this.state.currentPartialSupportFacilityValue === true || this.state.facility.partialSupport === true) && _.isNull(this.state.selectedBookingArea)) {
            valid = false
        }*/

        if (valid) {
            this.setState({ isValid: true })
            return true
        }
        else {
            this.setState({ isValid: false })
            return false
        }
    }

    // Return bool
    timeSlotsClash = (TS, TE, RS, RE, appointment_id) => {

        if(this.state.modalType === 'UPDATE'){
            // dont't clash for same appoinment
            if(appointment_id && this.props.selectedAppointment.id === appointment_id){
                return false
            }
        }

        let timeslotStart = moment(`01/01/2020 ${TS}`)
        let timeslotEnd = moment(`01/01/2020 ${TE}`)

        let rangeStart = moment(`01/01/2020 ${RS}`)
        let rangeEnd = moment(`01/01/2020 ${RE}`)

        // If timeslot interval of both are equal
        let case1 = (TS === RS && TE === RE)

        // If timslot interval is less than the compared time range
        let case2 = (timeslotStart.isBetween(rangeStart, rangeEnd) || timeslotEnd.isBetween(rangeStart, rangeEnd))

        // If timeslot interval is larger than the compared time range
        let case3 = (rangeStart.isBetween(timeslotStart, timeslotEnd) || rangeEnd.isBetween(timeslotStart, timeslotEnd))

        if (case1 || case2 || case3) {
            return true
        } else {
            return false
        }
    }

    // Return bool
    isTimeSlotRange = (TS, RS, RE) => {

        let timeslotStart = moment(`01/01/2020 ${TS}`)

        let rangeStart = moment(`01/01/2020 ${RS}`)

        let rangeEnd = moment(`01/01/2020 ${RE}`)

        // If timeslot interval of both are equal
        let case1 = (TS === RS ? true : false);

        // If timslot interval is less than the compared time range
        let case2 = (timeslotStart.isBetween(rangeStart, rangeEnd))

        if (case1 || case2) {
            return true
        } else {
            return false
        }
    }

    isTimeSlotExpired = (selectedDate, TS) => {

        let currentDate = moment(new Date())

        if (currentDate.isSame(selectedDate, 'day')) {
            let timeslotStart = moment(`${moment(currentDate).format('YYYY/MM/DD')} ${TS}`)
            if (timeslotStart.isBefore(currentDate)) {
                return true
            } else {
                return false
            }
        }
    }

    isAlreadyBooked = (date, startTime, endTime, facilityId) => {
        const isPartial = this.state.isPartial;
        const isAvailableCurrentBucket = this.state.appointmentsArray.some((curr) => {
            const AS = startTime.format('h:mm a')
            const AE = endTime.format('h:mm a')
            const TS = moment(`${curr.appointmentDate} ${curr.startTime}`).format('h:mm a')
            const TE = moment(`${curr.appointmentDate} ${curr.endTime}`).format('h:mm a');
            const clashing = this.timeSlotsClash(TS, TE, AS, AE, curr.id)

            return clashing && curr.appointmentDate === date && curr.facilityId === facilityId && !isPartial && curr.isPartial
        })
        const isAvailableOlderBucket = this.getBookedSlots(this.state.appointmentDate).some((curr) => {
            const AS = startTime.format('h:mm a')
            const AE = endTime.format('h:mm a')
            const TS = moment(curr.startTime).format('h:mm a')
            const TE = moment(curr.endTime).format('h:mm a');
            const clashing = this.timeSlotsClash(TS, TE, AS, AE, curr.id)

            return clashing === startTime.format('HH:mm') && moment(curr.appointmentDate).format('YYYY-MM-DD') === date && !isPartial && curr.partial
        })


        return isAvailableCurrentBucket || isAvailableOlderBucket;
    }

    onSaveAndNext = () => {
        if (this.isValid()) {
            let dateTimeString = `${moment(this.state.appointmentDate).format('YYYY/MM/DD')} ${this.state.selectedTimeSlot}`
            let appointmentDate = moment(this.state.appointmentDate).format('YYYY-MM-DD')
            let startTime = moment(dateTimeString).format('HH:mm')
            let serviceTime = (this.state.service.serviceHour * 60) + this.state.service.serviceMinute
            let endTime = moment(dateTimeString).add(serviceTime, 'minutes').format('HH:mm')
            const isPartial = this.state.facility.partialSupport && this.state.service.partialSupport;

            let appointment = {
                branchId: this.props.branchId,
                shopId: this.props.shopId,
                isWalking: "true",
                guestCustomer: false,
                email: this.state.email,
                employeeId: null,
                facilityId: this.state.facility.facilityId,
                serviceId: this.state.service.serviceId,
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                mobileNo: this.state.mobileNo,
                appointmentDate: appointmentDate,
                specialNote: this.state.specialNotes,
                halfCourtName: this.state.isFullDayBooking ? 'FULL' : isPartial ? this.state.halfCourtName : "FULL",
                isPartial: this.state.isFullDayBooking ? false : isPartial ? this.state.isPartial : false,
                isFullDay: this.state.isFullDayBooking,
                guests: this.state.guests
            }

            if(this.props.businessTypeId === 3) {
                let startTimeFormatted = moment(this.state.selectedTimeSlotStartTime, "h:mm a").format("HH:mm").toString()
                let endTimeFormatted = moment(this.state.selectedTimeSlotEndTime, "h:mm a").format("HH:mm").toString()

                if (this.state.isFullDayBooking) {
                    startTimeFormatted = moment(this.state.shopOpeningHours.ot, "h:mm a").format("HH:mm").toString();
                    endTimeFormatted = moment(this.state.shopOpeningHours.ct, "h:mm a").format("HH:mm").toString();
                }

                const {serviceTime, duration, durationFullDay} = this.prepareAmountsLogic();
                let otherPriceObjectArray = [];
                let fullAmount = this.state.noOfSlots ? this.props.businessTypeId === 3 ? this.state.isFullDayBooking ? (this.state.service.fullDayPrice > 0 ? Number.parseFloat(`${this.state.service.fullDayPrice }`).toFixed(2) : Number.parseFloat(`${((this.state.service.serviceFullPrice / serviceTime) * durationFullDay)}`).toFixed(2)): (((((this.state.selectedBookingArea === 'HALF_1' || this.state.selectedBookingArea === 'HALF_2') && this.state.service.partialSupport) ? this.state.service.servicePartialPrice : this.state.service.serviceFullPrice) / serviceTime) * duration) : (parseFloat(this.state.amount) * this.state.noOfSlots).toFixed(2) : this.state.amount;

                if (this.state.isFullDayBooking ) {
                    otherPriceObjectArray = this.calculateOtherPriceAmount(this.state.service.additionalPricingList, this.state.appointmentDate, null,null, true, this.state.service.isAdditionalPricingSupport);
                } else {
                    otherPriceObjectArray = this.calculateOtherPriceAmount(this.state.service.additionalPricingList, this.state.appointmentDate, this.state.selectedTimeSlotStartTime, this.state.selectedTimeSlotEndTime, false, this.state.service.isAdditionalPricingSupport);
                }

                if (otherPriceObjectArray.length > 0) {
                    let otherPrice = 0;

                    _.forEach(otherPriceObjectArray, (obj) => {
                        otherPrice = otherPrice + Number.parseFloat(this.state.isFullDayBooking ? obj.meta.fullPrice : (obj.otherTimeMinutes * (((isPartial && (this.state.selectedBookingArea === 'HALF_1' || this.state.selectedBookingArea === 'HALF_2')) ? obj.meta.partialPrice : obj.meta.fullPrice) / obj.serviceChargeMinutes)))
                    });

                    fullAmount = Number.parseFloat(otherPrice + Number.parseFloat(fullAmount));
                }

                appointment = {
                    ...appointment,
                    startTime: startTimeFormatted,
                    endTime: endTimeFormatted,
                    amount: Number.parseFloat(`${fullAmount}`).toFixed(2),
                    customizedSlots: this.state.noOfSlots,
                    additionalPricingListIds: _.map(otherPriceObjectArray, obj => obj.meta.id)
                }

            } else {
                appointment = {
                    ...appointment,
                    startTime: startTime.toString(),
                    endTime: endTime.toString(),
                    amount: this.state.amount,
                }
            }
            const formattedStartTime = moment(dateTimeString)
            const formattedEndTime = moment(dateTimeString).add(serviceTime, 'minutes')

            if (this.isAlreadyBooked(appointmentDate, formattedStartTime, formattedEndTime, appointment.facilityId)) {
                toast("Slot has already booked", { className: 'toast-custom', type: toast.TYPE["ERROR"] })
                return false;
            };

            let finalAppointmentArray = this.state.appointmentsArray;
            finalAppointmentArray.push(appointment);

            this.setState({ appointmentsArray: finalAppointmentArray });
            this.setState({ facility: {} });
            this.setState({ guests: [] });
            this.setState({ appointmentDate: null });
            this.setState({ startTime: null });
            this.setState({ selectedBookingArea: null });
            this.setState({ selectedTimeSlot: null });
            this.setState({ noOfSlots: null });
            this.setState({ isFullDayBooking: false });

            if (this.state.appointmentType === enumAppointment.RECURRING) {
                this.setState({ recurringMoment: true });
            } else {
                this.setState({ service: {} });
            }
        }
    }

    onSubmit = () => {

        const callback = async (addAppointmentResponse) => {
            const res = addAppointmentResponse && addAppointmentResponse.data;

            if(!res) {
                await confirm({
                    title: null,
                    message: addAppointmentResponse,
                    confirmText: "Ok",
                    cancelText: null,
                    confirmColor: "primary",
                    cancelColor: "link text-danger"
                });
                this.setState({ appointmentsArray: [] })
                this.setState({ recurredAppointmentArray: [] })
                this.setState({ isAddingAppointment: false, isError: true, errorMessage: addAppointmentResponse})
            }
            else if (res && (!res.data.unavailableAppointmentList || !res.data.unavailableAppointmentList.length)) {
                const responseStatus = Array.isArray(res.data.availableAppointmentList) && res.data.availableAppointmentList.length > 0 ? res.data.availableAppointmentList[0].status : null;
                // if unavailableAppointmentList is null/empty
                let self = this;
                setTimeout(() => {
                    if (self.props.isHandleByRedux) {
                        self.resetModalWithProps()
                    } else {
                        self.toggleModal(true)
                    }
                }, 500)
                this.setState({ appointmentsArray: [] })
                this.setState({ recurredAppointmentArray: [] })

                notify(responseStatus === 'Rejected' ? 'Your booking request has been cancelled.' : 'Processing Success!', 'SUCCESS')

                let branchId = this.props.selectedBranch.branchId;
                this.props.getAppointments(branchId);
            } else if(res && res.data.unavailableAppointmentList) {
                // for multiple & recurring appoinments
                if (res?.data?.availableAppointmentList?.length > 0) {
                    const allServices = this.props.services;
                    const allFacilities = this.props.facilities;

                    let result = await confirm({
                        title: (
                            <ul>
                                {res.data && res.data.unavailableAppointmentList.map(ap => <div>
                                    <li className='w-100'>
                                        <div className='d-flex w-100'>
                                            <div className='d-flex flex-column pr-2 small'>
                                                <div>{_.get(_.find(allServices, {serviceId: ap.serviceId}), 'serviceName', '')}</div>
                                                <div className='badge badge-info'>{_.get(_.find(allFacilities, {facilityId: ap.facilityId}), 'facilityName', '')}</div>
                                            </div>
                                            <div>{ap.startTime} {ap.appointmentDate}</div>
                                        </div>
                                    </li>
                                </div>)}
                            </ul>
                        ),
                        message: "Sorry, the selected slots are not available. Would you like to book only the available slots from your selection?",
                        confirmText: "Yes",
                        cancelText: "No",
                        confirmColor: "primary",
                        cancelColor: "link text-danger"
                    });

                    const status = result ? 'Active' : 'Rejected';

                    let availList = res.data.availableAppointmentList.map(ap => {
                        return {
                            ...ap,
                            additionalPricingListIds: ap.additionalPricingList?.length > 0 ? ap.additionalPricingList.map(ad => ad.id) : [],
                            halfCourtName: ap.courtName,
                            isPartial: ap.partial,
                            amount: ap.fee,
                            isWalking: "true",
                            appointmentDate: moment(ap.appointmentDate).format('YYYY-MM-DD'),
                            status
                        }
                    })

                    const submitObject = {
                        appointmentDTORqList: availList,
                        appointmentType: this.state.appointmentType
                    }

                    this.props.confirmAppointment(submitObject, callback);
                } else {
                    const message = "All selected appointment slots are fully booked. Please try different time slots."

                    this.setState({ isAddingAppointment: false, isError: true, errorMessage: message })
                    toast(message, {
                        className: "toast-custom",
                        type: toast.TYPE["WARNING"],
                    });

                    this.setState({ appointmentsArray: [] })
                    this.setState({ recurredAppointmentArray: [] })
                }
            }
        }

        if (this.state.appointmentType === enumAppointment.SINGLE && !this.isValid())
            return false;
        // if (this.isValid()) {

        // let phone = this.state.phone
        // let fullPhone = configs.countryCode + phone.slice(2, phone.length)
        // let fullName = this.state.firstName +' '+ this.state.lastName

        // let customer = {
        //     uid: this.state.phone,
        //     firstName: this.state.firstName,
        //     lastName: this.state.lastName,
        //     name: fullName,
        //     phone: fullPhone,
        //     image: DEFAULT_PROFILE_IMAGE,
        //     isWalking: true
        // }

        // let service = {
        //     id: this.state.service.id,
        //     name: this.state.service.name,
        //     serviceTime: this.state.service.serviceTime,
        // }

        // Fix times
        let dateTimeString = `${moment(this.state.appointmentDate).format('YYYY/MM/DD')} ${this.state.selectedTimeSlot}`
        let appointmentDate = moment(this.state.appointmentDate).format('YYYY-MM-DD')
        let startTime = moment(dateTimeString).format('HH:mm')
        let serviceTime = (this.state.service.serviceHour * 60) + this.state.service.serviceMinute
        let endTime = moment(dateTimeString).add(serviceTime, 'minutes').format('HH:mm')

        let appointment = {
            branchId: this.props.branchId,
            shopId: this.props.shopId,
            isWalking: "true",
            guestCustomer: false,
            email: this.state.email,
            employeeId: null,
            facilityId: this.state.facility.facilityId,
            serviceId: this.state.service.serviceId,
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            mobileNo: this.state.mobileNo,
            appointmentDate: appointmentDate,
            specialNote: this.state.specialNotes,
            halfCourtName: this.state.halfCourtName,
            isPartial: this.state.isPartial,
            isFullDay: typeof this.state.isFullDayBooking === "boolean" ? this.state.isFullDayBooking : false,
            guests: this.state.guests
        };

        if(this.props.businessTypeId === 3) {
            let startTimeFormatted = moment(this.state.selectedTimeSlotStartTime, "h:mm a").format("HH:mm").toString();
            let endTimeFormatted = moment(this.state.selectedTimeSlotEndTime, "h:mm a").format("HH:mm").toString();
            let noOfSlots = this.state.noOfSlots;

            if (this.state.isFullDayBooking) {
                startTimeFormatted = moment(this.state.shopOpeningHours.ot, "h:mm a").format("HH:mm").toString();
                endTimeFormatted = moment(this.state.shopOpeningHours.ct, "h:mm a").format("HH:mm").toString();
            }

            const {serviceTime, duration, durationFullDay} = this.prepareAmountsLogic();
            appointment = {
                ...appointment,
                startTime: startTimeFormatted,
                endTime: endTimeFormatted,
                amount: this.state.isFullDayBooking ? (this.state.service.fullDayPrice > 0 ? Number.parseFloat(`${this.state.service.fullDayPrice }`).toFixed(2) :Number.parseFloat(`${((this.state.service.serviceFullPrice / serviceTime) * durationFullDay)}`).toFixed(2)) : ((this.state.amount / serviceTime) * duration),
                customizedSlots: noOfSlots,
            }

        } else {
            appointment = {
                ...appointment,
                startTime: startTime.toString(),
                endTime: endTime.toString(),
                amount: this.state.amount,
            }
        }

        this.setState({ isAddingAppointment: true, isError: false, errorMessage: '' })

        let finalAppointmentArray = [];

        if (this.state.appointmentType === enumAppointment.MULTIPLE) {
            finalAppointmentArray = this.state.appointmentsArray;
        } else if (this.state.appointmentType === enumAppointment.SINGLE) {
            finalAppointmentArray = [appointment];
        } else if (this.state.appointmentType === enumAppointment.RECURRING) {
            finalAppointmentArray = this.state.recurredAppointmentArray.length > 0 ? this.state.recurredAppointmentArray : [appointment];
        }

        const submitObject = {
            appointmentDTORqList: finalAppointmentArray,
            appointmentType: this.state.appointmentType
        }

        this.props.submitAppointment(submitObject, callback)
        // }
    }

    onUpdate = () => {
        const callback = (success, updatedData) => {
            if (success) {
                // Fix times
                let dateTimeString = `${moment(this.state.appointmentDate).format('YYYY-MM-DD')} ${this.state.selectedTimeSlot}`
                let startTime = moment(dateTimeString)
                let endTime = moment(dateTimeString).add(this.state.service.serviceTime, 'minutes')

                this.props.setChanges(this.state.facility, this.state.service, this.state.appointmentDate, startTime, this.state.specialNotes, endTime, this.props.selectedAppointment.id, updatedData)
                let self = this;
                setTimeout(() => {
                    self.setState({ ...DEFAULT_MODAL_STATE })
                }, 1000)
                notify('Appointment updated successfully', 'SUCCESS')
            } else {
                this.setState({ isAddingAppointment: false, isError: true, errorMessage: 'Something went wrong! Please try again' })
            }
        }

        let dateTimeString = `${moment(this.state.appointmentDate).format('YYYY/MM/DD')} ${this.state.selectedTimeSlot}`
        let appointmentDate = moment(this.state.appointmentDate).format('YYYY-MM-DD')
        let startTime = moment(dateTimeString).format('HH:mm')
        let serviceTime = (this.state.service.serviceHour * 60) + this.state.service.serviceMinute
        let endTime = moment(dateTimeString).add(serviceTime, 'minutes').format('HH:mm')

        let updatedAppointment = {
            branchId: this.props.branchId,
            shopId: this.props.shopId,
            id: this.state.originalData.id,
            isWalking: "true",
            guestCustomer: this.props.selectedAppointment.guestCustomer,
            email: this.state.email,
            employeeId: null,
            facilityId: this.state.facility.facilityId,
            serviceId: this.state.service.serviceId,
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            mobileNo: this.state.mobileNo,
            appointmentDate: appointmentDate,
            specialNote: this.state.specialNotes,
            halfCourtName: this.state.halfCourtName,
            courtName: this.isBookingAreaEnabled() ? this.state.halfCourtName : "FULL",
            isPartial: this.isBookingAreaEnabled() ? this.state.isPartial : false,
            isFullDay: this.state.isFullDayBooking,
        }


        if(this.props.businessTypeId === 3) {
            const startTimeFormatted = moment(this.state.selectedTimeSlotStartTime, "h:mm a").format("HH:mm").toString()
            const endTimeFormatted = moment(this.state.selectedTimeSlotEndTime, "h:mm a").format("HH:mm").toString()

            const startTimeFullDay = moment(this.state.shopOpeningHours.ot, "h:mm a").format("HH:mm").toString()
            const endTimeFullDay = moment(this.state.shopOpeningHours.ct, "h:mm a").format("HH:mm").toString()

            const {serviceTime, duration, durationFullDay} = this.prepareAmountsLogic();

            let otherPriceObjectArray = [];
            let fullAmount = this.state.isFullDayBooking ? (this.state.service.fullDayPrice > 0 ? Number.parseFloat(`${this.state.service.fullDayPrice }`).toFixed(2) : Number.parseFloat(`${((this.state.service.serviceFullPrice / serviceTime) * durationFullDay)}`).toFixed(2))  : (((((this.state.selectedBookingArea === 'HALF_1' || this.state.selectedBookingArea === 'HALF_2') && this.state.service.partialSupport) ? this.state.service.servicePartialPrice : this.state.service.serviceFullPrice) / serviceTime) * duration);

            if (this.state.isFullDayBooking ) {
                otherPriceObjectArray = this.calculateOtherPriceAmount(this.state.service.additionalPricingList, this.state.appointmentDate, null,null, true, this.state.service.isAdditionalPricingSupport);
            } else {
                otherPriceObjectArray = this.calculateOtherPriceAmount(this.state.service.additionalPricingList, this.state.appointmentDate, this.state.selectedTimeSlotStartTime, this.state.selectedTimeSlotEndTime, false, this.state.service.isAdditionalPricingSupport);
            }

            if (otherPriceObjectArray.length > 0) {
                let otherPrice = 0;

                _.forEach(otherPriceObjectArray, (obj) => {
                    otherPrice = otherPrice + Number.parseFloat(this.state.isFullDayBooking ? obj.meta.fullPrice : (obj.otherTimeMinutes * ((this.state.displayBookingArea === "Full" ? obj.meta.fullPrice : obj.meta.partialPrice) / obj.serviceChargeMinutes)))
                });

                fullAmount = Number.parseFloat(otherPrice + Number.parseFloat(fullAmount)).toFixed(2);
            }

            updatedAppointment = {
                ...updatedAppointment,
                startTime: this.state.isFullDayBooking ? startTimeFullDay : startTimeFormatted,
                endTime: this.state.isFullDayBooking ? endTimeFullDay : endTimeFormatted,
                amount: Number.parseFloat(`${fullAmount}`).toFixed(2),
                customizedSlots: this.state.noOfSlots,
                guests: this.state.guests,
                additionalPricingListIds: _.map(otherPriceObjectArray, obj => obj.meta.id)
            }

        } else {
            updatedAppointment = {
                ...updatedAppointment,
                startTime: startTime.toString(),
                endTime: endTime.toString(),
                amount: this.state.amount,
            }
        }

        this.setState({ isAddingAppointment: true, isError: false, errorMessage: '' })
        this.props.updateAppointment(this.state.originalData.id, updatedAppointment, callback)
    }

    removeAppointmentFromBulk = (i) => {
        const _appointmentsArray = this.state.appointmentsArray;
        _appointmentsArray.splice(i, 1);
        this.setState({ appointmentsArray: [..._appointmentsArray] });
    }

    setRecurredAppointmentArray = (recurredAppointmentArray) => {
        this.setState({ recurredAppointmentArray: recurredAppointmentArray });
    }

    onSelectService = (service) => {

        let facilities = []  // Employees of the selected service
        let partialValue = _.get(service, 'partialSupport')

        _.forEach(this.props.facilities, (obj) => {
            let serviceIds = _.get(obj, 'serviceIdList', [])
            let includes = _.includes(serviceIds, service.serviceId)
            if (includes) {
                facilities.push(obj)
            }
        })

        this.setState({ service: service, facilities: facilities, facility: {}, appointmentDate: null, selectedTimeSlot: null, disabledCalendarDates: null, selectedBookingArea: null, currentPartialSupportServiceValue: partialValue }, () => this.checkChanges('service'))
    }

    onSelectEmployee = async (facility) => {
        let disabledCalendarDates = await this.getDisabledCalendarDates(facility.facilityId);
        this.availableBookingAreas();
        if (this.state.isPartial === false) {
            this.setState({ halfCourtName: "FULL" })
        } else {
            this.setState({ selectedBookingArea: null })
        }
        this.setState({ facility: facility, disabledCalendarDates: disabledCalendarDates, appointmentDate: null, selectedTimeSlot: null, currentPartialSupportFacilityValue: facility.partialSupport }, () => this.checkChanges('employee'))
    }

    onSelectDate = (appointmentDate, modifiers = {}) => {

        if (!modifiers.disabled) {
            // const shopOpeningHours = _.get(this.props.openingHours, `${moment(new Date(date)).format('dddd').toLowerCase()}`, null)
            let dayName = moment(new Date(appointmentDate)).format('dddd')
            let shopOpeningHours = _.filter(this.props.openingHours, (a) => { return a.day === dayName })
            if (shopOpeningHours !== null) {

                let appointmentDateVal = moment(appointmentDate);
                let appointmentDateStr = appointmentDateVal.format('YYYY-MM-DD');
                let ot = _.get(shopOpeningHours[0], "openTime", null);
                let ct = _.get(shopOpeningHours[0], "closeTime", null);
                let openingTime = moment(`${appointmentDateStr} ${ot}:00 ${this.state.offSet}`, 'YYYY-MM-DD HH:mm Z').toDate();
                let closingTime = moment(`${appointmentDateStr} ${ct}:00 ${this.state.offSet}`, 'YYYY-MM-DD HH:mm Z').toDate();

                let bookedSlots = this.getBookedSlots(appointmentDate)
                console.log("BOOKED SLOTS", bookedSlots)
                this.setState({ appointmentDate: appointmentDate, selectedTimeSlot: null, selectedBookingArea: null, availableFacilityAreaList: [] }, () => this.checkChanges('appointmentDate'))
                this.setState({shopOpeningHours: {ot, ct}});
                this.setState({recurringSelectedDate: appointmentDate});

                if (!this.props.edit || this.state.editAnotherDate){
                    this.setState({isFullDayBooking: false});
                }

                // get service time in minutes
                let serviceTime = parseInt(this.state.service.serviceHour * 60) + parseInt(this.state.service.serviceMinute)

                // build time slots for appointment time
                this.buildtimeSlots(TIME_SLOT_SIZE, this.state.facility.facilityId, appointmentDate, bookedSlots, openingTime, closingTime, serviceTime)
            }
        }
    }

    // TODO: Check for fully booked days and disable them
    getDisabledCalendarDates = (facilityId) => {
        return new Promise((resolve, reject) => {

            let dates = []
            let before = { before: new Date() }     // Disable days before today

            let daysOfWeek = []     // Array of numbers corresponding to the day of the week (Ex: sunday is 0, saturday is 6)
            _.forEach(this.props.openingHours, (value, key) => {
                if (value.status === "Inactive") {
                    daysOfWeek.push(WEEK_MAP[value.day])
                }
            })

            let shopClosedDates = { daysOfWeek: daysOfWeek }

            _.forEach(this.props.timeOffs, (obj) => {
                if (facilityId === obj.facilityId && _.isEmpty(obj.startTime)) {
                    dates.push(new Date(obj.facilityOffDate))    // timeOff dates
                }
            })

            _.forEach(this.props.holidays, (obj) => {
                if (_.isEmpty(obj.startTime)) {
                    dates.push(new Date(obj.holiday))    // shop holiday dates
                }
            })

            dates.push(shopClosedDates)
            dates.push(before)
            console.log('disabledCalendarDates', dates)

            resolve(dates)
        })
    }

    getBookedSlots = (selectedDate) => {

        let bookedSlots = []

        _.forEach(this.props.appointments, (appoint) => {
            if (this.state.facility.facilityId === appoint.facilityId) {
                if (moment(new Date(appoint.appointmentDate)).isSame(selectedDate, 'day')) {
                    if (!(this.props.edit && appoint.id === this.props.selectedAppointment.id)) {
                        bookedSlots.push({
                            appointmentDate: new Date(appoint.appointmentDate),
                            startTime: new Date(`Sun nov 14 2021 ${appoint.startTime}:00 GMT${this.state.offSet}`),
                            endTime: new Date(`Sun nov 14 2021 ${appoint.endTime}:00 GMT${this.state.offSet}`),
                            partial: appoint.partial,
                            halfCourtName: appoint.courtName,
                            id: appoint.id,
                        })
                    }
                }
            }
        })

        //If it is edit mode remove from the booked slots
        if (this.props.edit) {
            _.remove(bookedSlots, (bookedSlot) => {
                return bookedSlot.startTime == this.state.startTime
            });
        }
        return bookedSlots
    }

    //create a object array with start time and count
    getBookedAppointmentCount = (selectedDate) => {
        let bookedAppointmentCount = []
        let bookedSlots = this.getBookedSlots(selectedDate)
        console.log("bookedSlots", bookedSlots);

        _.forEach(bookedSlots, (slot) => {
            let startTime = moment(slot.startTime).format('h:mm a')
            let matchedSlot = _.find(bookedAppointmentCount, function (item) { return item.startTime == startTime });

            if (matchedSlot) {
                console.log("matchedSlot", matchedSlot);
            } else {
                bookedAppointmentCount.push({
                    startTime,
                    count: 1
                })
            }
        })

        console.log("bookedAppointmentCount", bookedAppointmentCount);
    }

    onSelectTimePriceCalculation = () => {
        this.setNoOfSlotsInSports(this.state.selectedTimeSlotEndTime, this.state.selectedTimeSlotStartTime)

        if (this.state.currentPartialSupportFacilityValue === true && this.state.currentPartialSupportServiceValue === true) {
            if (new String(this.state.selectedBookingArea).valueOf() == new String('Full').valueOf()) {
                this.populatePriceForFullFacilityArea();
            } else {
                this.populatePriceForHalfFacilityArea();
            }
        } else {
            this.populatePriceForFullFacilityArea();
        }
    }

    populatePriceForHalfFacilityArea = () => {
        if (this.state.service.advancedPriceSupport && this.state.service.advancedPriceList.length > 0) {
            let filteredByDay = []

            let dayName = moment(new Date(this.state.appointmentDate)).format('dddd')
            let dateTimeString = `${moment(this.state.appointmentDate).format('YYYY/MM/DD')} ${this.state.selectedTimeSlot}`
            let startTime = moment(dateTimeString).format('HH:mm')

            for (var i = 0; i < this.state.service.advancedPriceList.length; i++) {
                if (this.state.service.advancedPriceList[i].day == dayName && this.state.service.advancedPriceList[i].status == 'Active') {
                    filteredByDay.push(this.state.service.advancedPriceList[i])
                }
            }

            if (filteredByDay.length !== 0) {
                for (var i = 0; i < filteredByDay.length; i++) {
                    if (this.isTimeSlotRange(startTime, filteredByDay[i].startTime, filteredByDay[i].endTime)) {
                        this.setState({ amount: filteredByDay[i].partialPrice })
                        break;
                    } else {
                        this.setState({ amount: this.state.service.servicePartialPrice })
                    }
                }
            } else {
                this.setState({ amount: this.state.service.servicePartialPrice })
            }
        } else {
            this.setState({ amount: this.state.service.servicePartialPrice })
        }
    }

    populatePriceForFullFacilityArea = () => {
        if (this.state.service.advancedPriceSupport && this.state.service.advancedPriceList.length > 0) {

            let filteredByDay = []

            let dayName = moment(new Date(this.state.appointmentDate)).format('dddd')
            let dateTimeString = `${moment(this.state.appointmentDate).format('YYYY/MM/DD')} ${this.state.selectedTimeSlot}`
            let startTime = moment(dateTimeString).format('HH:mm')

            for (var i = 0; i < this.state.service.advancedPriceList.length; i++) {
                if (this.state.service.advancedPriceList[i].day == dayName && this.state.service.advancedPriceList[i].status == 'Active') {
                    filteredByDay.push(this.state.service.advancedPriceList[i])
                }
            }

            if (filteredByDay.length !== 0) {
                for (var i = 0; i < filteredByDay.length; i++) {
                    if (this.isTimeSlotRange(startTime, filteredByDay[i].startTime, filteredByDay[i].endTime)) {
                        this.setState({ amount: filteredByDay[i].fullPrice })
                        break;
                    } else {
                        this.setState({ amount: this.state.service.serviceFullPrice })
                    }
                }
            } else {
                this.setState({ amount: this.state.service.serviceFullPrice })
            }
        } else {
            this.setState({ amount: this.state.service.serviceFullPrice })
        }
    }

    onChangeAppointmentType = (appointmentType) => {
        this.setState({ appointmentType })
    }

    renderAppointmentTypeDropDown = () => {
        return (
            <FormGroup>
                <div className="d-flex">
                    <Label ><span className="required">*</span>Appointment type</Label>
                    <InfoTooltip
                    image={Images.info}
                    className="info-list-tooltip pointer"
                    text={
                    <ul>
                        {/* <li>Single - Add one-time appointment for a selected service type</li> */}
                        <li>Multiple - Add more than one appointment for several services</li>
                        <li>Recurring - Add appointments to recur after defined frequency until defined end date</li>
                    </ul>}
                    />
                </div>
                <UncontrolledDropdown disabled={this.state.appointmentsArray.length > 0 && (this.state.appointmentType === enumAppointment.RECURRING || this.state.appointmentType === enumAppointment.MULTIPLE) ? true : false} >
                    <DropdownToggle
                        caret color='info'>
                        {_.isEmpty(this.state.appointmentType) ? 'select' : this.state.appointmentType}
                    </DropdownToggle>
                    <DropdownMenu className="w-50"
                        modifiers={{
                            disabled: true,
                            setMaxHeight: {
                                enabled: true,
                                order: 890,
                                fn: (data) => {
                                    return {
                                        ...data,
                                        styles: {
                                            ...data.styles,
                                            overflow: 'auto',
                                            maxHeight: '280px',
                                            zIndex: 2000,
                                        },
                                    };
                                },
                            },
                        }}>
                        {/* <DropdownItem
                            key={1}
                            className="d-flex align-items-baseline px-1"
                            onClick={() => this.onChangeAppointmentType(enumAppointment.SINGLE)}
                        >
                            <div className="d-flex flex-column" style={{ width: "calc(100%)" }}>
                                <p style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>{enumAppointment.SINGLE}</p>
                            </div>
                        </DropdownItem> */}
                        <DropdownItem
                            key={2}
                            className="d-flex align-items-baseline px-1"
                            onClick={() => this.onChangeAppointmentType(enumAppointment.MULTIPLE)}
                        >
                            <div className="d-flex flex-column" style={{ width: "calc(100%)" }}>
                                <p style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>{enumAppointment.MULTIPLE}</p>
                            </div>
                        </DropdownItem>
                        <DropdownItem
                            key={2}
                            className="d-flex align-items-baseline px-1"
                            onClick={() => this.onChangeAppointmentType(enumAppointment.RECURRING)}
                        >
                            <div className="d-flex flex-column" style={{ width: "calc(100%)" }}>
                                <p style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>{enumAppointment.RECURRING}</p>
                            </div>
                        </DropdownItem>
                    </DropdownMenu>
                </UncontrolledDropdown>
            </FormGroup>
        )
    }

    renderServicesDropDown = () => {
        return (
            <UncontrolledDropdown disabled={this.state.isAddingAppointment}>
                <DropdownToggle
                    // className="w-75 text-left"
                    caret color={`${_.isEmpty(this.state.service) && !this.state.isValid ? 'danger' : 'info'}`}>
                    {_.isEmpty(this.state.service) ? 'Select' : this.state.service.serviceName}
                </DropdownToggle>
                <DropdownMenu className="w-50"
                    modifiers={{
                        setMaxHeight: {
                            enabled: true,
                            order: 890,
                            fn: (data) => {
                                return {
                                    ...data,
                                    styles: {
                                        ...data.styles,
                                        overflow: 'auto',
                                        maxHeight: '280px',
                                        zIndex: 2000,
                                    },
                                };
                            },
                        },
                    }}>

                    {_.map(_.filter(this.props.services, function (o) { return o.status == "Active"; }), (obj, index) => {
                        if (!_.isEmpty(obj.facilityId)) {
                            return (
                                <DropdownItem
                                    key={index}
                                    // disabled={!obj.available}
                                    onClick={() => this.onSelectService(obj)}
                                    className="d-flex align-items-baseline px-2"
                                >
                                    <div className="d-flex flex-column" style={{ width: "calc(100% - 60px)" }}>
                                        <div style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>{`${obj.serviceName} ${obj.status !== "Active" ? '(Unavailable)' : ''}`}</div>
                                        <p className="text-muted" style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }} >{_.get(obj, "description", "")}</p>
                                    </div>
                                    <div className="ml-auto" >{configs.currencyPrefix}{_.get(obj, "serviceFullPrice", "")}</div>
                                </DropdownItem>
                            )
                        }
                    })}
                </DropdownMenu>
            </UncontrolledDropdown>
        )
    }

    renderEmployeesDropDown = () => {
        if (!_.isEmpty(this.state.service)) {
            return (
                <FormGroup>
                    <Label ><span className="required">*</span>{businessTypes(this.props.businessTypeId, 'business_category_singular')}</Label>
                    <UncontrolledDropdown  >
                        <DropdownToggle
                            disabled={this.state.isAddingAppointment}
                            // className="w-50 text-left"
                            caret color={`${_.isEmpty(this.state.facility) && !this.state.isValid ? 'danger' : 'info'}`}>
                            {_.isEmpty(this.state.facility) ? 'Select' : this.state.facility.facilityName}
                        </DropdownToggle>
                        <DropdownMenu className="w-50"
                            modifiers={{
                                setMaxHeight: {
                                    enabled: true,
                                    order: 890,
                                    fn: (data) => {
                                        return {
                                            ...data,
                                            styles: {
                                                ...data.styles,
                                                overflow: 'auto',
                                                maxHeight: '280px',
                                                zIndex: 2000,
                                            },
                                        };
                                    },
                                },
                            }}>
                            {_.map(this.state.facilities, (obj, index) => {
                                return (
                                    <DropdownItem
                                        key={index}
                                        disabled={obj.status === "Active" ? false : true}
                                        // onClick={() => this.onSelectEmployee(obj)}
                                        onClick={() => { this.setState({ currentPartialSupportFacilityValue: obj.partialSupport }, () => { this.onSelectEmployee(obj); }) }}
                                        className="d-flex align-items-baseline px-1"
                                    >
                                        <div className="d-flex flex-column" style={{ width: "calc(100%)" }}>
                                            <p style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>{obj.facilityName}</p>
                                        </div>
                                    </DropdownItem>
                                )
                            })}
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </FormGroup>
            )
        }
    }

    getAllTimeSlots = (interval, ot, ct, serviceTime) => {
        // for sports
        let startTimeSlotsArray = []
        let endTimeSlotsArray = []
        let openTime = moment(ot)
        let closeTime = moment(ct)
        let timeSlotValueStart = moment(openTime);
        let timeSlotValueEnd = moment(openTime);

        while (timeSlotValueStart.isSameOrBefore(closeTime)){
            startTimeSlotsArray.push(timeSlotValueStart.format('h:mm a'))
            timeSlotValueStart = timeSlotValueStart.add(interval, 'minutes')
        }

        while(timeSlotValueEnd.isSameOrBefore(closeTime)){
            endTimeSlotsArray.push(timeSlotValueEnd.format('h:mm a'))
            timeSlotValueEnd = timeSlotValueEnd.add(serviceTime, 'minutes')
        }

        this.setState({allTimeSlots: endTimeSlotsArray, startTimeSlots: startTimeSlotsArray, endTimeSlots: endTimeSlotsArray});

        return {allTimeSlots: endTimeSlotsArray, startTimeSlots: startTimeSlotsArray, endTimeSlots: endTimeSlotsArray}
    }

    handleOnClickStartTime = (startTime, startTimeSlots, endTimeSlots, availableStartTimes, selectedDate) => {
        this.setState({ selectedTimeSlot: startTime, selectedTimeSlotStartTime:startTime })

        let serviceTime = parseInt(this.state.service.serviceHour * 60) + parseInt(this.state.service.serviceMinute);
        let endTimeVal = moment(`1970-01-01 ${startTime}`, 'YYYY-MM-DD hh:mm a').add(serviceTime, 'minutes');
        let endTime = moment(endTimeVal).format('hh:mm a');

        this.setEndTimeSlotsArray(startTime, availableStartTimes, selectedDate);
        this.handleOnClickEndTime(endTime, startTime);
    }

    handleOnClickEndTime = (endTime, startTime) => {
        this.setNoOfSlotsInSports(endTime, startTime? startTime : this.state.selectedTimeSlotStartTime)

        this.setState({ selectedTimeSlotEndTime: endTime, selectedBookingArea: null,
            availableFacilityAreaList: [], amount: null },
            () => { this.checkChanges('selectedTimeSlot');
            { (this.state.currentPartialSupportServiceValue !== true || this.state.currentPartialSupportFacilityValue !== true) ?
                this.populatePriceForFullFacilityArea() : this.availableBookingAreas(); } this.availableBookingAreas(); })
    }

    setEndTimeSlotsArray = (startTime, availableStartTimes, selectedDate) => {
        let dayName = moment(new Date(selectedDate)).format('dddd');
        let shopOpeningHours = _.filter(this.props.openingHours, (a) => { return a.day === dayName });
        let ct = _.get(shopOpeningHours[0], "closeTime", null);
        let closeTime = moment(new Date(moment(`1970-01-01 ${ct}`).format('YYYY-MM-DD HH:mm')))

        let endTimeSlotsArray = [];
        let endTimeSlotsArrayFinal = [];
        let serviceTime = (this.state.service.serviceHour * 60) + this.state.service.serviceMinute;
        let startTimeVal = moment(new Date(moment(`1970-01-01 ${startTime}`, 'YYYY-MM-DD h:mm a').format('YYYY-MM-DD HH:mm')));
        let timeSlotValueEnd = moment(startTimeVal);

        while(timeSlotValueEnd.isSameOrBefore(closeTime)){
            endTimeSlotsArray.push(timeSlotValueEnd.format('h:mm a'))
            timeSlotValueEnd = timeSlotValueEnd.add(serviceTime, 'minutes')
        }

        for (let i = 1; i < endTimeSlotsArray.length; i++) {
            if(availableStartTimes.includes(endTimeSlotsArray[i-1])){
                if (endTimeSlotsArrayFinal.length === 0) {
                    endTimeSlotsArrayFinal.push(endTimeSlotsArray[i]);
                } else {
                    const lastElement = endTimeSlotsArrayFinal[endTimeSlotsArrayFinal.length - 1];
                    // get index of endTimeSlotsArray in lastElement
                    const index = endTimeSlotsArray.indexOf(lastElement);
                    if (index !== -1) {
                        const a = index + 1;
                        if (endTimeSlotsArray[a] === endTimeSlotsArray[i]) {
                            endTimeSlotsArrayFinal.push(endTimeSlotsArray[i]);
                        }
                    }
                }
            }
        }

        this.setState({endTimeSlots: endTimeSlotsArray, endTimeSlotDisabled: endTimeSlotsArrayFinal})
    }

    setNoOfSlotsInSports = (endTime, startTime) => {
        if(this.props.businessTypeId === 3) {
            let duration = moment.utc(endTime, 'h:mm a').diff(moment.utc(startTime, 'h:mm a'), 'minutes')
            let serviceTime = (this.state.service.serviceHour * 60) + this.state.service.serviceMinute
            let noOfSlots = duration/serviceTime

            if (this.state.isFullDayBooking) {
                let startTime = moment(this.state.shopOpeningHours.ot, "h:mm a");
                let endTime = moment(this.state.shopOpeningHours.ct, "h:mm a");
                let duration = moment.duration(endTime.diff(startTime));

                noOfSlots = Math.ceil(duration / (serviceTime * 60 * 1000));
            }

            this.setState({noOfSlots: noOfSlots});
        }
    }

    fullDayBooking = () => {
        this.setState({isFullDayBooking: !this.state.isFullDayBooking}, () => {
            this.setNoOfSlotsInSports(this.state.selectedTimeSlotEndTime, this.state.selectedTimeSlotStartTime);
            this.onSelectTimePriceCalculation();

            const appointmentDate = this.state.appointmentDate
            let dayName = moment(new Date(appointmentDate)).format('dddd')
            let shopOpeningHours = _.filter(this.props.openingHours, (a) => { return a.day === dayName })
            if (shopOpeningHours !== null) {

                let appointmentDateVal = moment(appointmentDate);
                let appointmentDateStr = appointmentDateVal.format('YYYY-MM-DD');
                let ot = _.get(shopOpeningHours[0], "openTime", null);
                let ct = _.get(shopOpeningHours[0], "closeTime", null);

                let openingTime = moment(`${appointmentDateStr} ${ot}:00 ${this.state.offSet}`, 'YYYY-MM-DD HH:mm Z').toDate();
                let closingTime = moment(`${appointmentDateStr} ${ct}:00 ${this.state.offSet}`, 'YYYY-MM-DD HH:mm Z').toDate();

                let bookedSlots = this.getBookedSlots(appointmentDate)
                this.setState({ selectedTimeSlot: null, selectedBookingArea: null, availableFacilityAreaList: [] }, () => this.checkChanges('appointmentDate'))
                this.setState({shopOpeningHours: {ot, ct}});

                // get service time in minutes
                let serviceTime = parseInt(this.state.service.serviceHour * 60) + parseInt(this.state.service.serviceMinute)

                // build time slots for appointment time
                this.buildtimeSlots(TIME_SLOT_SIZE, this.state.facility.facilityId, appointmentDate, bookedSlots, openingTime, closingTime, serviceTime)
            }
        });
    }

    renderTime = (timeSlots) => {
        const { appointmentDate, facility: { facilityId: selectedFacilityId }, appointmentsArray } = this.state;
        let bookedSlots = [];

        if (appointmentDate) {
            bookedSlots = this.getBookedSlots(appointmentDate);
            bookedSlots = [
                ...bookedSlots,
                ..._.filter(appointmentsArray, appoint =>
                    appoint.facilityId === selectedFacilityId &&
                    moment(new Date(appoint.appointmentDate)).isSame(appointmentDate, 'day')
                )
            ];
        }

        if (this.state.appointmentDate) {
            return (
                <>
                {(this.props.businessTypeId === 3 && (
                    (this.state.editAnotherDate && this.state.canBookFullDay) ||
                    (!this.state.editAnotherDate && (this.props.edit || this.state.canBookFullDay))
                )) && bookedSlots.length === 0 && <>
                    <FormGroup className={'pt-4 pb-4 px-4 border-top border-bottom d-flex justify-content-between align-items-center'}>
                        <div>
                            <Input
                                type="checkbox" name="is_full_day" id="full_day"
                                checked={this.state.isFullDayBooking}
                                onChange={this.fullDayBooking}
                            />
                            <Label check for="full_day">Full Day Booking</Label>
                        </div>
                    </FormGroup>
                </>}
                <FormGroup>
                    {!_.isEmpty(timeSlots) ?
                        (this.props.businessTypeId === 3 ?
                            (!this.state.isFullDayBooking && <div className='mb-2'>
                                <div className='float-left w-50 mb-3'>
                                    <Label ><span className="required">*</span>Start Time</Label>
                                    <UncontrolledDropdown className="w-100">
                                        <DropdownToggle
                                            disabled={this.state.isAddingAppointment}
                                            className="w-75 text-left"
                                            caret
                                            color='info'>
                                            {_.isEmpty(this.state.selectedTimeSlot) ? 'Select' : this.state.selectedTimeSlot}
                                        </DropdownToggle>
                                        <DropdownMenu
                                            className="w-50"
                                            modifiers={{
                                                setMaxHeight: {
                                                    enabled: true,
                                                    order: 890,
                                                    fn: (data) => {
                                                        return {
                                                            ...data,
                                                            styles: {
                                                                ...data.styles,
                                                                overflow: 'auto',
                                                                maxHeight: '400px',
                                                                zIndex: 2000
                                                            },
                                                        };
                                                    },
                                                },
                                            }}>
                                            <>{this.state.startTimeSlots && _.map(this.state.startTimeSlots, (time, index) => {
                                                return (
                                                    <DropdownItem
                                                        key={index}
                                                        disabled={!timeSlots.includes(time)}
                                                        onClick={() => this.handleOnClickStartTime(time, this.state.startTimeSlots, this.state.endTimeSlots, this.state.timeSlots, this.state.appointmentDate)}>
                                                        <div className="d-flex justify-content-between">
                                                            <div>{time}</div>
                                                        </div>
                                                    </DropdownItem>
                                                )
                                            })}</>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                </div>
                                <div className='float-right w-50 mb-3'>
                                    <Label ><span className="required">*</span>End Time</Label>
                                    <UncontrolledDropdown>
                                        <DropdownToggle
                                            disabled={this.state.isAddingAppointment}
                                            className="w-75 text-left"
                                            caret
                                            color='info'>
                                            {_.isEmpty(this.state.selectedTimeSlot) ? 'Select' : this.state.selectedTimeSlotEndTime}
                                        </DropdownToggle>
                                        <DropdownMenu
                                            className="w-50"
                                            modifiers={{
                                                setMaxHeight: {
                                                    enabled: true,
                                                    order: 890,
                                                    fn: (data) => {
                                                        return {
                                                            ...data,
                                                            styles: {
                                                                ...data.styles,
                                                                overflow: 'auto',
                                                                maxHeight: '400px',
                                                                zIndex: 2000
                                                            },
                                                        };
                                                    },
                                                },
                                            }}>
                                            <>{this.state.endTimeSlots && _.map(this.state.endTimeSlots, (time, index) => {
                                                return (
                                                    <DropdownItem
                                                        key={index}
                                                        disabled={this.state.endTimeSlotDisabled && !this.state.endTimeSlotDisabled.includes(time)}
                                                        onClick={() => this.handleOnClickEndTime(time)}>
                                                        <div className="d-flex justify-content-between">
                                                            <div>{time}</div>
                                                        </div>
                                                    </DropdownItem>
                                                )
                                            })}</>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                </div>
                                {this.state.service.isRequireGuests &&
                                    <div className='pt-2'>
                                        <Button color="secondary" onClick={() => this.setState({ isAddGuestsModalOpen: true })}>Add Guest Players</Button>
                                        <div>Current number of guests {this.state.guests.length}</div>

                                        {this.isValidNextButtonDisabled() && <div className='required'>Required minimum number of guest(s): {this.state.service.minNoOfGuests}</div>}
                                        {this.renderAddGuestModal()}
                                    </div>
                                }
                            </div>)
                            :
                            (<div>
                                <Label ><span className="required">*</span>Time</Label>
                                <UncontrolledDropdown>
                                    <DropdownToggle
                                        disabled={this.state.isAddingAppointment}
                                        className="w-50 text-left"
                                        caret
                                        color='info'>
                                        {_.isEmpty(this.state.selectedTimeSlot) ? 'Select' : this.state.selectedTimeSlot}
                                    </DropdownToggle>
                                    <DropdownMenu className="w-50"
                                        modifiers={{
                                            setMaxHeight: {
                                                enabled: true,
                                                order: 890,
                                                fn: (data) => {
                                                    return {
                                                        ...data,
                                                        styles: {
                                                            ...data.styles,
                                                            overflow: 'auto',
                                                            maxHeight: '400px',
                                                            zIndex: 2000
                                                        },
                                                    };
                                                },
                                            },
                                        }}>
                                        {_.map(timeSlots, (time, index) => {

                                            return (
                                                <DropdownItem
                                                    key={index}
                                                    onClick={() => { this.setState({ selectedTimeSlot: time, selectedBookingArea: null, availableFacilityAreaList: [], amount: null }, () => { this.checkChanges('selectedTimeSlot'); { (this.state.currentPartialSupportServiceValue !== true || this.state.currentPartialSupportFacilityValue !== true) ? this.populatePriceForFullFacilityArea() : this.availableBookingAreas(); } this.availableBookingAreas(); }) }}>
                                                    <div className="d-flex justify-content-between">
                                                        <div>{time}</div>
                                                    </div>
                                                </DropdownItem>
                                            )
                                        })}
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </div>)
                        )
                        : <div> &emsp; Not available, Check another date </div>}
                </FormGroup>
                </>
            )
        }
    }

    renderAddGuestModal = () => {
        return (
            <Modal isOpen={this.state.isAddGuestsModalOpen} size="lg">
                <ModalBody>
                    <h5 className="pb-3 mb-3 border-bottom">Manage Guests</h5>
                    {this.state.isShowAddGuestForm ?
                        <div>
                            <Row className='p-2'>
                                <Col lg="6" >
                                    <Label>Full Name</Label>
                                    <Input
                                        autoFocus={true}
                                        value={this.state.guestFullName}
                                        disabled={this.state.isAddingService}
                                        invalid={!this.state.isGuestFullNameValid}
                                        className={`border w-100 ${!this.state.isGuestFullNameValid ? 'border-danger' : 'border-success'}`}
                                        required={true}
                                        onChange={(e) => this.onGuestFullNameChange((e.target.value))}
                                        placeholder='Enter guest full name'
                                    />
                                    <FormFeedback>{this.state.guestFullNameError}</FormFeedback>
                                </Col>
                                <Col lg="6" >
                                    <Label>NIC</Label>
                                    <Input
                                        value={this.state.guestNIC}
                                        disabled={this.state.isAddingService}
                                        invalid={!this.state.isGuestNICValid}
                                        required={true}
                                        className={`border w-100 ${!this.state.isGuestNICValid ? 'border-danger' : 'border-success'}`}
                                        onChange={(e) => this.onGuestNICChange((e.target.value).trim())}
                                        placeholder='Enter guest NIC'
                                    />
                                    <FormFeedback>{this.state.guestNICError}</FormFeedback>
                                </Col>
                            </Row>
                            <div className='text-right'>
                                <Button
                                    className="mr-2"
                                    size="md"
                                    onClick={() => { this.setState({ guestFullName: '', guestNIC: '', isShowAddGuestForm: false }) }}
                                >Cancel</Button>
                                <Button
                                    size="md"
                                    className='my-auto ml-auto an-btn-info an-emp-add-btn shadow-sm btn btn-secondary'
                                    onClick={() => this.onAddNewGuest()}
                                    disabled={!this.state.isGuestFullNameValid || !this.state.isGuestNICValid}
                                >Add</Button>
                            </div>
                        </div> : <div className='text-center'>
                            <Button
                                size="md"
                                className='my-auto ml-auto an-btn-info an-emp-add-btn shadow-sm btn btn-secondary'
                                onClick={() => { this.setState({ isShowAddGuestForm: true }) }}
                            >Add New Guest</Button>
                        </div>

                    }
                    <Table className="mt-4">
                        <thead>
                            <tr>
                                <th>Full Name</th>
                                <th>NIC</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.guests.map((guest, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{guest.fullName}</td>
                                        <td>{guest.nic}</td>
                                        <td><Button className="an-btn-info" onClick={() => { this.onClickRemoveGuest(guest) }}>Remove</Button></td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={this.onCancelAddingGuests}>Cancel</Button>
                    <Button color="secondary" className='an-btn-info an-emp-add-btn shadow-sm btn btn-secondary' onClick={this.onSubmitAddingGuests}>Submit</Button>
                </ModalFooter>
            </Modal>
        )
    }

    onAddNewGuest = () => {
        let guest = {
            fullName: this.state.guestFullName,
            nic: this.state.guestNIC,
        }

        if (guest.fullName && guest.nic) {
            this.setState({
                guests: [...this.state.guests, guest],
                guestFullName: '',
                guestNIC: '',
                isGuestFullNameValid: false,
                isGuestNICValid: false,
                isShowAddGuestForm: false
            }, () => this.checkChanges('guests'))
        }
    }

    onClickRemoveGuest = (guest) => {
        const current = this.state.guests;

        _.remove(current, function (el) {
            return el.nic === guest.nic
        });

        this.setState({ guests: current }, () => this.checkChanges('guests'))
    }

    onGuestFullNameChange = (guestFullName) => {
        const regex = new RegExp("^[a-zA-Z ]{3,40}$")
        const isValid = regex.test(guestFullName);
        const guestFullNameError = isValid ? false : "Please insert a valid full name"

        this.setState({ guestFullName, guestFullNameError, isGuestFullNameValid: isValid }, () => this.checkChanges('guests'));
        return isValid
    }

    onGuestNICChange = (guestNIC) => {
        const regex = new RegExp("^[a-zA-Z0-9 ]{3,40}$")
        const duplicated = this.state.guests.some(guest => guest.nic === guestNIC);
        const isValid = regex.test(guestNIC) && !duplicated;
        const guestNICError = isValid ? false : duplicated ? "Duplicate NIC" : "Please insert a valid nic";

        this.setState({ guestNIC, guestNICError, isGuestNICValid: isValid }, () => this.checkChanges('guests'))
        return isValid
    }

    onCancelAddingGuests = () => {
        this.setState((prevState) => {
            return { isAddGuestsModalOpen: !prevState.isAddGuestsModalOpen }
        })
    }

    onSubmitAddingGuests = () => {
        this.setState((prevState) => {
            return { isAddGuestsModalOpen: !prevState.isAddGuestsModalOpen }
        })
    }

    isValidNextButtonDisabled = () => {
        return this.state.service.isRequireGuests && this.state.guests.length > 0 && this.state.guests.length < this.state.service.minNoOfGuests
    }

    isBookingAreaEnabled = () => {
        return !this.state.isFullDayBooking && this.state.selectedTimeSlot && this.state.currentPartialSupportServiceValue === true && (this.state.currentPartialSupportFacilityValue === true || this.state.facility.partialSupport === true)
    }

    renderBookingArea = () => {
        if (this.isBookingAreaEnabled()) {
            return (
                <FormGroup>
                    <Label ><span className="required">*</span>Booking Area</Label>
                    {this.state.availableFacilityAreaList !== 0 ?
                        <UncontrolledDropdown>
                            <DropdownToggle
                                disabled={this.state.isAddingAppointment}
                                className="w-50 text-left"
                                caret
                                color='info'>
                                {_.isEmpty(this.state.selectedBookingArea) ? 'Select' : this.state.displayBookingArea}

                            </DropdownToggle>
                            <DropdownMenu className="w-50"
                                modifiers={{
                                    setMaxHeight: {
                                        enabled: true,
                                        order: 890,
                                        fn: (data) => {
                                            return {
                                                ...data,
                                                styles: {
                                                    ...data.styles,
                                                    overflow: 'auto',
                                                    maxHeight: '400px',
                                                    zIndex: 2000
                                                },
                                            };
                                        },
                                    },
                                }}>

                                {_.map(this.state.displayFacilityAreaList, (area, index) => {
                                    return (
                                        <DropdownItem
                                            key={index}
                                            onClick={() => { this.setState({ selectedBookingArea: area, displayBookingArea: area }, () => { this.onSelectTimePriceCalculation(); this.getDropDownAvailableBookingArea(); this.checkChanges('courtName') }) }}>
                                            <div className="d-flex justify-content-between">
                                                <div>{area}</div>
                                            </div>
                                        </DropdownItem>
                                    )
                                })}
                            </DropdownMenu>
                        </UncontrolledDropdown>
                        : <div> &emsp; Booking areas aren't available, Check another time </div>}
                </FormGroup>
            )
        }
    }

    availableBookingAreas = () => {
        if (this.state.selectedTimeSlot !== null) {
            let bookedSlots = this.getBookedSlots(this.state.appointmentDate)

            let interval = (this.state.service.serviceHour * 60) + this.state.service.serviceMinute

            let TS = this.state.selectedTimeSlot
            let TE = moment(this.state.selectedTimeSlot, "h:mm a").add(interval, 'minutes').format('h:mm a')
            if(this.props.businessTypeId === 3 && this.state.selectedTimeSlotEndTime){
                TE = this.state.selectedTimeSlotEndTime
            }

            let courtArea = []
            let defaultCourtNameList = ["FULL", "HALF_1", "HALF_2"]

            let isClashing = false

            _.forEach(bookedSlots, (obj) => {
                let AS = moment(obj.startTime).format('h:mm a')
                let AE = moment(obj.endTime).format('h:mm a')

                // Check if timeslots clash with previous appointments
                isClashing = this.timeSlotsClash(TS, TE, AS, AE, obj.id)

                if (isClashing) {
                    if (obj.partial) {
                        courtArea.push(obj.halfCourtName)
                    } else {
                        // Stop loop
                        return false
                    }
                }
            })

            if (courtArea.length === 0) {
                this.setState({ availableFacilityAreaList: this.state.defaultFacilityAreaList })
                this.populateDropDownAvailableBookingArea(this.state.defaultFacilityAreaList, this.state.defaultFacilityAreaList.length);
            } else if (courtArea.length === 2) {
                this.setState({ availableFacilityAreaList: 0 })
                // this.populateDropDownAvailableBookingArea(0, 2);
            } else if (courtArea.length === 1) {
                let courtList = this.removeSpecificArrayElement(defaultCourtNameList, courtArea[0])
                let finalizedCourtList = this.removeSpecificArrayElement(courtList, "FULL")
                this.setState({ availableFacilityAreaList: finalizedCourtList, currentCourtAreaList: courtList })
                this.populateDropDownAvailableBookingArea(finalizedCourtList, finalizedCourtList.length)
            } else {
                this.setState({ availableFacilityAreaList: this.state.defaultFacilityAreaList })
                this.populateDropDownAvailableBookingArea(this.state.defaultFacilityAreaList, this.state.defaultFacilityAreaList.length);
            }
        } else if (this.props.edit && this.props.selectedAppointment.startTime !== null) {
            let appointmentSelectedTimeSlot = moment(`Sun nov 14 2021 ${this.state.startTime}:00 GMT${this.state.offSet}`).format('h:mm a')

            let bookedSlots = this.getBookedSlots(this.state.appointmentDate)

            let interval = (this.state.service.serviceHour * 60) + this.state.service.serviceMinute

            let TS = appointmentSelectedTimeSlot
            let TE = moment(appointmentSelectedTimeSlot, "h:mm a").add(interval, 'minutes').format('h:mm a')
            if(this.props.businessTypeId === 3){
                TE = moment(`Sun nov 14 2021 ${this.state.endTime}:00 GMT${this.state.offSet}`).format('h:mm a')
            }

            let courtArea = []
            let defaultCourtNameList = ["FULL", "HALF_1", "HALF_2"]

            let isClashing = false

            _.forEach(bookedSlots, (obj) => {
                let AS = moment(obj.startTime).format('h:mm a')
                let AE = moment(obj.endTime).format('h:mm a')

                // Check if timeslots clash with previous appointments
                isClashing = this.timeSlotsClash(TS, TE, AS, AE, obj.id)

                if (isClashing) {
                    if (obj.partial) {
                        courtArea.push(obj.halfCourtName)
                    } else {
                        // Stop loop
                        return false
                    }
                }
            })

            if (courtArea.length === 0) {
                this.setState({ availableFacilityAreaList: this.state.defaultFacilityAreaList })
                this.populateDropDownAvailableBookingArea(this.state.defaultFacilityAreaList, this.state.defaultFacilityAreaList.length);
            } else if (courtArea.length === 2) {
                this.setState({ availableFacilityAreaList: 0 })
                // this.populateDropDownAvailableBookingArea(0, 2);
            } else if (courtArea.length === 1) {
                let courtList = this.removeSpecificArrayElement(defaultCourtNameList, courtArea[0])
                this.setState({ availableFacilityAreaList: courtList, currentCourtAreaList: courtList })
                this.populateDropDownAvailableBookingArea(courtList, courtList.length)
            } else {
                this.setState({ availableFacilityAreaList: this.state.defaultFacilityAreaList })
                this.populateDropDownAvailableBookingArea(this.state.defaultFacilityAreaList, this.state.defaultFacilityAreaList.length);
            }
        }
    }

    getDropDownAvailableBookingArea = () => {
        if (new String(this.state.selectedBookingArea).valueOf() == new String('Full').valueOf()) {
            this.setState({ selectedBookingArea: 'FULL', halfCourtName: 'FULL', isPartial: false })
        } else {
            if (this.state.availableFacilityAreaList.length === 1) {
                this.setState({ selectedBookingArea: 'HALF_2', halfCourtName: 'HALF_2', isPartial: true })
            } else {
                this.setState({ selectedBookingArea: 'HALF_1', halfCourtName: 'HALF_1', isPartial: true })
            }
        }
    }

    populateDropDownAvailableBookingArea = (array, arrayLength) => {
        let dropDownList = []
        for (let record = 0; record < arrayLength; record++) {
            if (new String(array[record]).valueOf() == new String('FULL').valueOf()) {
                dropDownList.push("Full")
            } else {
                dropDownList.push("Half")
            }
        }
        let uniqueDropDownList = [...new Set(dropDownList)]
        this.setState({ displayFacilityAreaList: uniqueDropDownList })
    }

    removeSpecificArrayElement = (array, indexValue) => {
        const index = array.indexOf(indexValue);
        if (index > -1) {
            array.splice(index, 1);
        }
        return array;
    }

    prepareAmountsLogic = () => {
        let serviceTime, duration, durationFullDay;

        if(this.props.businessTypeId === 3) {
            let startTime = moment(this.state.selectedTimeSlotStartTime, "h:mm a");
            let endTime = moment(this.state.selectedTimeSlotEndTime, "h:mm a");
            let startTimeFullDay = moment(this.state.shopOpeningHours.ot, "h:mm a");
            let endTimeFullDay = moment(this.state.shopOpeningHours.ct, "h:mm a");

            serviceTime = (this.state.service.serviceHour * 60) + this.state.service.serviceMinute;
            duration = moment.utc(endTime, 'h:mm a').diff(moment.utc(startTime, 'h:mm a'), 'minutes');
            durationFullDay = moment.utc(endTimeFullDay, 'h:mm a').diff(moment.utc(startTimeFullDay, 'h:mm a'), 'minutes');
        }

        return {serviceTime, duration, durationFullDay};
    }

    renderAmount = () => {
        if (this.state.selectedBookingArea || (this.state.selectedTimeSlot && (this.state.currentPartialSupportServiceValue !== true || this.state.currentPartialSupportFacilityValue !== true))) {
            const {serviceTime, duration, durationFullDay} = this.prepareAmountsLogic();
            let isFullDayPriceBooking = false;
            let otherPriceCharging = 0;
            let otherPriceObjectArray = [];
            const isPartial = this.state.facility.partialSupport && this.state.service.partialSupport;

            if (this.props.businessTypeId === 3 && this.state.isFullDayBooking && this.state.noOfSlots) {
                isFullDayPriceBooking = true;
                otherPriceObjectArray = this.calculateOtherPriceAmount(this.state.service.additionalPricingList, this.state.appointmentDate, null, null, true, this.state.service.isAdditionalPricingSupport);
            } else {
                otherPriceObjectArray = this.calculateOtherPriceAmount(this.state.service.additionalPricingList, this.state.appointmentDate, this.state.selectedTimeSlotStartTime, this.state.selectedTimeSlotEndTime, false, this.state.service.isAdditionalPricingSupport);
            }

            if (otherPriceObjectArray.length > 0) {
                otherPriceObjectArray = _.map(otherPriceObjectArray, obj => {
                    const charging = Number.parseFloat(isFullDayPriceBooking ? obj.meta.fullPrice : (obj.otherTimeMinutes * (((isPartial && (this.state.selectedBookingArea === 'HALF_1' || this.state.selectedBookingArea === 'HALF_2')) ? obj.meta.partialPrice : obj.meta.fullPrice) / obj.serviceChargeMinutes)));

                    otherPriceCharging = otherPriceCharging + charging;
                   return {...obj, charging}
                })
            }

            const normalAmount = this.state.noOfSlots ? this.props.businessTypeId === 3 ? this.state.isFullDayBooking ? (this.state.service.fullDayPrice > 0 ? Number.parseFloat(`${this.state.service.fullDayPrice }`).toFixed(2) : Number.parseFloat(`${((this.state.service.serviceFullPrice / serviceTime) * durationFullDay)}`).toFixed(2)): (((((isPartial && (this.state.selectedBookingArea === 'HALF_1' || this.state.selectedBookingArea === 'HALF_2')) && this.state.service.partialSupport) ? this.state.service.servicePartialPrice : this.state.service.serviceFullPrice) / serviceTime) * duration) : (parseFloat(this.state.amount) * this.state.noOfSlots).toFixed(2) : this.state.amount

            return (
                <p>
                    <div id={'amountText'}>Amount:{configs.currencyPrefix}{Number.parseFloat(`${normalAmount}`).toFixed(2)}</div>
                    {otherPriceCharging > 0 && <>
                        {_.map(otherPriceObjectArray, (object,) => {
                            return <>
                                <div className="pt-1">Other Price Charging: {configs.currencyPrefix}{Number.parseFloat(object.charging).toFixed(2)}</div>
                                <div className="text-muted small">({object.meta.description})</div>
                            </>
                        })}
                        <h6 className="mt-2">Full Amount: {configs.currencyPrefix}{Number.parseFloat(Number.parseFloat(normalAmount) + Number.parseFloat(otherPriceCharging)).toFixed(2)}</h6>
                    </>}
                </p>
            )
        } else if (this.state.isFullDayBooking) {
            const {serviceTime, durationFullDay} = this.prepareAmountsLogic();
            let otherPriceCharging = 0;
            let otherPriceObjectArray = this.calculateOtherPriceAmount(this.state.service.additionalPricingList, this.state.appointmentDate, null, null, true, this.state.service.isAdditionalPricingSupport);
            if (otherPriceObjectArray.length > 0) {
                otherPriceObjectArray = _.map(otherPriceObjectArray, obj => {
                    const charging = Number.parseFloat(obj.meta.fullPrice);

                    otherPriceCharging = otherPriceCharging + charging;
                    return {...obj, charging}
                })
            }
            return (
                <p>
                    <div id={'amountText'}>Amount: {configs.currencyPrefix}{Number.parseFloat(`${((this.state.service.fullDayPrice > 0 ? Number.parseFloat(this.state.service.fullDayPrice) :((this.state.service.serviceFullPrice / serviceTime)) * durationFullDay))}`).toFixed(2)}</div>
                    {otherPriceCharging > 0 && <>
                        {_.map(otherPriceObjectArray, (object,) => {
                            return <>
                                <div className="pt-1">Other Price Charging: {configs.currencyPrefix}{Number.parseFloat(object.charging).toFixed(2)}</div>
                                <div className="text-muted small">({object.meta.description})</div>
                            </>
                        })}
                        <h6 className="mt-2">Full Amount: {configs.currencyPrefix}{Number.parseFloat(Number.parseFloat(`${((this.state.service.fullDayPrice > 0 ?  Number.parseFloat(this.state.service.fullDayPrice) :((this.state.service.serviceFullPrice / serviceTime)) * durationFullDay))}`) + Number.parseFloat(otherPriceCharging)).toFixed(2)}</h6>
                    </>}
                </p>
            )
        }
    }

    calculateOtherPriceAmount = (service, appointmentDate, startTime, endTime, isFullDay, isAdditionalPricingSupport) => {
        const additionalPricing = _.filter(service, {day: moment(appointmentDate).format('dddd'), status: 'Active'});
        const otherPriceArray = [];

        if (isAdditionalPricingSupport && additionalPricing.length > 0) {
            _.forEach(additionalPricing, function(obj) {
                const otherPriceObject = {
                    serviceChargeMinutes: 0,
                    otherTimeMinutes: 0,
                    meta: {}
                }
                if (isFullDay) {
                    otherPriceObject.otherTimeMinutes = moment(obj.endTime, 'HH:mm').diff(moment(obj.startTime, 'HH:mm'), 'minutes');
                    otherPriceObject.meta = {...obj};
                    otherPriceObject.serviceChargeMinutes = otherPriceObject.otherTimeMinutes;

                   otherPriceArray.push(otherPriceObject);
                } else {
                    const selectedTimeSlotStartTime = startTime;
                    const selectedTimeSlotEndTime = endTime;
                    const otherPriceStartTime = obj.startTime;
                    const otherPriceEndTime = obj.endTime;
                    const additionalPriceStartTime = moment(selectedTimeSlotStartTime, 'h:mm a').isBetween(moment(otherPriceStartTime, 'HH:mm'), moment(otherPriceEndTime, 'HH:mm'), 'minutes', '[]');
                    const additionalPriceEndTime = moment(selectedTimeSlotEndTime, 'h:mm a').isBetween(moment(otherPriceStartTime, 'HH:mm'), moment(otherPriceEndTime, 'HH:mm'), 'minutes', '[]');
                    const selectedTimeStart = moment(otherPriceStartTime, 'HH:mm').isBetween(moment(selectedTimeSlotStartTime, 'h:mm a'), moment(selectedTimeSlotEndTime, 'h:mm a'), 'minutes', '[]');
                    const selectedTimeEnd = moment(otherPriceEndTime, 'HH:mm').isBetween(moment(selectedTimeSlotStartTime, 'h:mm a'), moment(selectedTimeSlotEndTime, 'h:mm a'), 'minutes', '[]');

                    if (additionalPriceEndTime && additionalPriceStartTime) {
                        otherPriceObject.otherTimeMinutes = moment(selectedTimeSlotEndTime, 'h:mm a').diff(moment(selectedTimeSlotStartTime, 'h:mm a'), 'minutes');
                    }else if (additionalPriceEndTime) {
                        otherPriceObject.otherTimeMinutes = moment(selectedTimeSlotEndTime, 'h:mm a').diff(moment(otherPriceStartTime, 'HH:mm'), 'minutes');
                    } else if (additionalPriceStartTime) {
                        otherPriceObject.otherTimeMinutes = moment(otherPriceEndTime, 'HH:mm').diff(moment(selectedTimeSlotStartTime, 'h:mm a'), 'minutes');
                    } else if (selectedTimeStart && selectedTimeEnd) {
                        otherPriceObject.otherTimeMinutes = moment(otherPriceEndTime, 'HH:mm').diff(moment(otherPriceStartTime, 'HH:mm'), 'minutes');
                    }

                    if (otherPriceObject.otherTimeMinutes) {
                        otherPriceObject.serviceChargeMinutes = moment(obj.endTime, 'HH:mm').diff(moment(obj.startTime, 'HH:mm'), 'minutes');
                        otherPriceObject.meta = {...obj};
                        otherPriceArray.push(otherPriceObject);
                    }
                }
            });

            return otherPriceArray;
        }

        return otherPriceArray;
    }

    renderModal = () => {

        return (
            <Modal isOpen={this.props.isHandleByRedux ? this.props.addAppointmentModalConfig.isOpen : this.state.isModalOpen} id="add-appoint-modal" >
                <ModalHeader >{this.props.edit ? "Update Appointment" : "New Appointment"}</ModalHeader>
                <ModalBody>
                    <Form>
                        <Row form>
                            <Col md={6}>
                                <FormGroup>
                                    <Label ><span className="required">*</span>First Name</Label>
                                    <Input
                                        id="firstName"
                                        Value={this.state.firstName}
                                        invalid={!!this.state.firstNameError}
                                        placeholder="Enter customer first name"
                                        disabled={this.props.edit || this.state.isDisabledCustomerfirstName || this.state.isAddingAppointment}
                                        className={`border ${this.state.firstName === '' && !this.state.isValid ? 'border-danger' : ''}`}
                                        onChange={(e) => this.checkFirstNameValidity((e.target.value).trim())} />
                                    <FormFeedback>{this.state.firstNameError}</FormFeedback>
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label >Last Name</Label>
                                    <Input
                                        id="lastName"
                                        Value={this.state.lastName}
                                        placeholder="Enter customer last name"
                                        invalid={typeof this.state.lastNameError === "boolean" ? this.state.lastNameError : this.state.lastNameError !== ''}
                                        disabled={this.props.edit || this.state.isDisabledCustomerlastName || this.state.isAddingAppointment}
                                        onChange={(e) => this.checkLastNameValidity((e.target.value))}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row form>
                            <Col md={6}>
                                <FormGroup>
                                    <Label >Email Address</Label>
                                    <Input
                                        id="email"
                                        type="email"
                                        invalid={!!this.state.emailError}
                                        value={this.state.email}
                                        placeholder={!this.props.isEditing && "Enter Email"}
                                        disabled={this.props.edit || this.state.isDisabledCustomeremail || this.state.isAddingAppointment}
                                        className={`border ${this.state.emailError ? 'border-danger' : ''}`}
                                        onChange={(e) => this.checkEmailValidity(e.target.value)}
                                    />
                                    <FormFeedback>{this.state.emailError}</FormFeedback>
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label ><span className="required">*</span>Phone</Label>
                                    <Input
                                        id="phone"
                                        type="tel"
                                        invalid={!!this.state.phoneError}
                                        Value={this.state.mobileNo}
                                        placeholder={!this.props.isEditing && "Enter customer phone"}
                                        disabled={this.props.edit || this.state.isDisabledCustomermobileNo || this.state.isAddingAppointment}
                                        className={`w-100 border ${this.state.mobileNo === '' && !this.state.isValid ? 'border-danger' : ''}`}
                                        onChange={(e) => this.checkPhoneValidity(e.target.value)} />
                                    <FormFeedback>{this.state.phoneError}</FormFeedback>
                                    <FormText>{configs.phoneNumberExample}</FormText>
                                </FormGroup>
                            </Col>

                        </Row>

                        {!this.props.edit && (
                            <FormGroup>
                                {this.renderAppointmentTypeDropDown()}
                            </FormGroup>
                        )}


                        {!this.state.recurringMoment && <FormGroup>
                            <Label ><span className="required">*</span>{businessTypes(this.props.businessTypeId, 'service')}</Label>
                            {this.renderServicesDropDown()}
                        </FormGroup>}

                        {!this.state.recurringMoment && this.renderEmployeesDropDown()}

                        {!_.isEmpty(this.state.facility) &&
                            <FormGroup >
                                <Label ><span className="required">*</span>Date</Label>
                                <div className="text-center">
                                    <DayPicker
                                        onDayClick={(date, modifiers) => {
                                            this.setState({editAnotherDate: true}, () => {
                                                this.onSelectDate(date, modifiers);
                                            });
                                        }}
                                        selectedDays={this.state.appointmentDate}
                                        modifiers={{
                                            disabled: this.state.disabledCalendarDates
                                        }}
                                        firstDayOfWeek={1}
                                    />
                                </div>
                            </FormGroup>
                        }

                        {this.renderTime(this.state.timeSlots)}

                        {this.renderBookingArea()}

                        {this.renderAmount()}

                        {this.state.appointmentDate && <FormGroup>
                            <Label >Special Notes</Label>
                            <TextareaAutosize
                                placeholder="Add special notes (Optional)"
                                className="note-area"
                                value={this.state.specialNotes}
                                minRows={4}
                                maxRows={7}
                                disabled={this.state.isAddingAppointment}
                                onChange={(e) => { this.setState({ specialNotes: e.target.value }, () => this.checkChanges('specialNote')) }}
                            />
                        </FormGroup>
                        }
                    </Form>

                    {/* Validation message */}
                    {!this.state.isValid && <div className="text-danger">Please provide all the required information</div>}
                    {/* Error message */}
                    {this.state.isError && <div className="text-danger">{this.state.errorMessage}</div>}
                    {this.state.appointmentsArray.length > 0 && this.state.appointmentType === enumAppointment.MULTIPLE && <MultipleAppointmentGrid services={this.props.services} facilities={this.props.facilities} appointments={this.state.appointmentsArray} removeAppointmentFromBulk={this.removeAppointmentFromBulk} />}
                    {this.state.appointmentsArray.length > 0 && this.state.appointmentType === enumAppointment.RECURRING && (
                        <RecurringAppointmentPanel
                            facilities={this.props.facilities}
                            appointments={this.state.appointmentsArray}
                            setRecurredAppointmentArray={this.setRecurredAppointmentArray}
                            selectedService={this.state.service}
                            amount={this.state.amount}
                            timeOffs={this.props.timeOffs}
                            holidays={this.props.holidays}
                            selectedAppointmentDate={this.state.recurringSelectedDate}
                            shopOpeningHours={this.props.openingHours}
                        />
                    )}

                </ModalBody>

                <ModalFooter style={{ justifyContent: 'flex-start' }}>
                    <Button color="secondary" disabled={this.state.isAddingAppointment} onClick={() => {
                        if (this.props.isHandleByRedux) {
                            this.resetModalWithProps()
                        } else {
                            this.toggleModal(true)
                        }
                    }}>Cancel</Button>
                    {!this.props.edit && this.state.appointmentType === enumAppointment.MULTIPLE && <Button color="secondary" disabled={this.isValidNextButtonDisabled() || this.state.isAddingAppointment} onClick={this.onSaveAndNext}>Add</Button>}
                    {this.state.appointmentsArray.length === 0 && this.state.appointmentType === enumAppointment.RECURRING && <Button color="secondary" disabled={this.state.isAddingAppointment} onClick={this.onSaveAndNext}>Recur</Button>}
                    {this.props.edit ?
                        <Button
                            disabled={this.checkUpdateButtonDisability()}
                            onClick={() => this.onUpdate()}
                            style={{ width: '76px', backgroundColor: "#f76969", borderColor: 'transparent' }}   // TODO:  UI - Need a responsive solution instead of 76px
                        >
                            {this.state.isAddingAppointment ? <Spinner color="white" size="sm" /> : 'Update'}
                        </Button> :
                        (this.state.appointmentType === enumAppointment.MULTIPLE && this.state.appointmentsArray.length === 0) || (this.state.appointmentType === enumAppointment.RECURRING && this.state.recurredAppointmentArray.length === 0) ? '' :
                            <Button
                                disabled={(this.state.appointmentType === enumAppointment.RECURRING ? (this.state.recurredAppointmentArray.length <= 1 ? true : this.checkButtonDisability()) : this.checkButtonDisability())}
                                onClick={() => this.onSubmit()}
                                style={{ width: '76px', backgroundColor: "#f76969", borderColor: 'transparent' }}   // TODO:  UI - Need a responsive solution instead of 76px
                            >
                                {this.state.isAddingAppointment ? <Spinner color="white" size="sm" /> : 'Submit'}
                            </Button>}
                </ModalFooter>
            </Modal>
        )
    }

    isAppointmentModalDisabled = () => {
        return (
            !this.props.isSubscribed ||
            this.props.selectedBranch.status === "Inactive" ||
            this.props.facilities?.length < 1 ||
            this.props.services?.length < 1
        )
    }

    renderButton = () => {
        if (this.props.edit) {
            return (
                <Button className="an_new_appointment_edit_btn" size={'sm'} disabled={!this.props.isSubscribed} onClick={() => this.toggleModal(false)}>
                    <span >Edit</span>
                </Button>
            )
        } else {
            return (
                <div>
                    <Button className="an_new_appointment_btn d-none d-md-block" disabled={this.isAppointmentModalDisabled()} onClick={() =>{
                        if (this.props.isHandleByRedux) {
                            this.resetModalWithProps(true)
                        } else {
                            this.toggleModal(false)
                        }
                    }}>
                        {/* <span className="d-none d-sm-block" >+ New Appointment</span> */}
                        <div className="d-flex ">
                            <div className="ml-auto">
                                <img src={Images.add} height={26} alt="appointment-add-btn"></img>
                            </div>
                            {/*TODO: Rename "Appointment" to "Booking" per RCTC requirements, managed via config, and shop accordingly. */}
                            <div className="pl-2">Booking</div>
                        </div>
                    </Button>
                    <div onClick={this.props.isSubscribed && this.props.selectedBranch.status === "Active" && !this.isAppointmentModalDisabled() ? () => {
                        if (this.props.isHandleByRedux) {
                            this.resetModalWithProps(true)
                        } else {
                            this.toggleModal(false)
                        }
                    } : null} className={`d-flex d-md-none flex-column fixed-bottom ${this.isAppointmentModalDisabled() ? 'an_new_appointment_flot_disabled' : 'an_new_appointment_flot'} justify-content-center align-items-center`} >
                        <div className="an_new_appointment_flot_img shadow" >
                            <PlusIcon />
                        </div>
                        {/*TODO: Rename "Appointment" to "Booking" per RCTC requirements, managed via config, and shop accordingly. */}
                        <span className="an_new_appointment_flot_text" >Booking </span>
                    </div>
                </div>

            )
        }
    }

    resetModalWithProps = (isCustomerModalOpen) => {
        const defaultState = {
            ...DEFAULT_MODAL_STATE,
            appointmentsArray: [],
            recurredAppointmentArray: [],
        }

        this.setState((prevState) => {
            return { ...defaultState, phoneError: '' }
        });

        if (isCustomerModalOpen){
            this.setState({ isOpenSearchCustomerModal: true });
        } else{
            this.props.toggleAddAppointmentModal()
        }
    }

    isValidPhoneNumber = (phoneNumber) => {
        const phoneRegex = /^[0-9]{10}$/;
        return phoneRegex.test(phoneNumber);
    }

    toggle = () =>{
        this.setState({isOpenSearchCustomerModal: false})
    }

    handleSearch = async () =>{
        const { mobileNo } = this.state;

        if (!this.state.mobileNo) {
            this.setState({ phoneError: 'Phone number is required' });
            return;
        }

        if (!this.isValidPhoneNumber(mobileNo)) {
            this.setState({ phoneError: 'Invalid phone number format' });
            return;
        }

        this.setState({ isSearching: true });


        const result = await fetchCustomers(this.props.selectedBranch.branchId, '', '', '', this.state.mobileNo, 0);
        const customers  = result.data?.data;

        this.props.toggleAddAppointmentModal();

        if (customers?.total > 0) {
            const customer = customers.pageList[0];

            this.setState({
                gender: customer.gender,
                firstName: customer.customerFirstName,
                lastName: customer.customerLastName,
                mobileNo: customer.contactNo,
                email: customer.email,
                isDisabledCustomerfirstName: true,
                isDisabledCustomerlastName: true,
                isDisabledCustomeremail: true,
                isDisabledCustomermobileNo: true,
                isDisabledCustomergender: true,
                isOpenSearchCustomerModal: false
            })
        }  else{
            this.setState({
                isOpenSearchCustomerModal: false,
                isDisabledCustomermobileNo: false,
                mobileNo: mobileNo
            })
        }
        this.setState({ isSearching: false });
    }

    renderCustomerSearchModal = () => {
        return (<Modal isOpen={this.state.isOpenSearchCustomerModal}>
            <ModalHeader>Search Customer</ModalHeader>
            <ModalBody>
                <FormGroup>
                    <Label><span class="required">*</span>Phone Number</Label>
                    <Input
                        type="tel"
                        name="phone"
                        id="phone"
                        placeholder="Enter phone number"
                        value={this.state.mobileNo}
                        onChange={(e) => this.checkPhoneValidity(e.target.value)}
                        onKeyPress={(e) => {
                            if (e.key === 'Enter' && !this.state.isSearching) {
                                e.preventDefault(); // Prevent default action to avoid duplicate requests
                                this.handleSearch();
                            }
                        }}
                        invalid={typeof this.state.phoneError === "boolean" ? this.state.phoneError : this.state.phoneError !== ''}
                    />
                    <FormFeedback>{this.state.phoneError}</FormFeedback>
                    <FormText>{configs.phoneNumberExample}</FormText>
                </FormGroup>
            </ModalBody>
            <ModalFooter>
                <Button
                    color="primary"
                    onClick={this.handleSearch}
                    disabled={this.state.isSearching || !this.isValidPhoneNumber(this.state.mobileNo)} >
                    {this.state.isSearching ? <Spinner size="sm" /> : 'Search'}
                </Button>
                <Button color="secondary" onClick={this.toggle}>Cancel</Button>
            </ModalFooter>
        </Modal>)
    }

    render() {
        return (
            <React.Fragment>
                {this.renderModal()}
                {this.state.isWarningModalOpen && <WarningModal text="Please create sports and assign facilities before create an appointment" onClose={this.handleModalClose}/>}
                {this.renderButton()}
                {this.renderCustomerSearchModal()}
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        addAppointmentModalConfig: state.baseComponents.addAppointmentModalConfig
    };
};

export default connect(mapStateToProps, { getAppointments, confirmAppointment, toggleAddAppointmentModal })(AddAppointmentForFacilityModal);
