import {CLEAR_STORE} from "../actions/types";

const INITIAL_STATE = {
    storeVersion1: true,
}
export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CLEAR_STORE:
            return {
                ...INITIAL_STATE
            }
        default:
            return state
    }
}
