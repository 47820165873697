import { fetchCovidDetails, searchCovidByPhone } from './../api/api'

import {
    GET_COVID_DATA,
    GET_COVID_DATA_SUCCESS,
    GET_COVID_DATA_ERROR,

    SEARCH_COVID_BY_PHONE,
    SEARCH_COVID_BY_PHONE_SUCCESS,
    SEARCH_COVID_BY_PHONE_ERROR
} from '../actions/types'

export const getCovidData = (slug) => {
    return async (dispatch) => {

        dispatch({ type: GET_COVID_DATA })

        try {
            let covidData = await fetchCovidDetails(slug)
            dispatch({ type: GET_COVID_DATA_SUCCESS, payload: { covidData } })
        } catch (error) {
            console.log(error)
            dispatch({ type: GET_COVID_DATA_ERROR })
        }
    }
}

export const searchCovidCustByPhone = (slug, phone) => {
    return async (dispatch) => {
        dispatch({ type: SEARCH_COVID_BY_PHONE })
        try {
            let covidData = await searchCovidByPhone(slug, phone)
            dispatch({ type: SEARCH_COVID_BY_PHONE_SUCCESS, payload: { covidData } })
        } catch (error) {
            console.log(error)
            dispatch({ type: SEARCH_COVID_BY_PHONE_ERROR })
        }
    }
}