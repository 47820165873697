import React, { useState } from 'react'
import { Button, Modal, ModalBody, ModalFooter, Label } from 'reactstrap';

function WarningModal(props) {

    const [isWarningModalOpen, setIsWarningModalOpen] = useState(true)

    function handleClose() {
        setIsWarningModalOpen(false);
        props.onClose();
    }

    return (
        <Modal isOpen={isWarningModalOpen}>
            <ModalBody>
                <Label >{props.text}</Label>
            </ModalBody>

            <ModalFooter style={{ justifyContent: 'flex-end' }}>
                <Button color="secondary" onClick={handleClose}>OK</Button>
            </ModalFooter>
        </Modal>

    )
}
export default WarningModal;