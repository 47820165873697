import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import moment from "moment-timezone"
import axios from 'axios';

import { configs } from './configs'


export function logout(callBack) {
  firebase.auth().signOut().then(() => {
    callBack(true)
  }).catch((error) => {
    console.log(error)
    callBack(false)
  });
}

export async function login(email, password) {
  let loggedInDate = new Date();
  localStorage.setItem('loggedInDate', loggedInDate);

  const loginData = {
    password: password,
    portal: "Admin",
    username: email
  }

  const res = await axios.post(`${configs.baseURL}/b4u-core/user/login`, loginData)
  return res
}

export async function signUp(shopData) {
  const res = await axios.post(`${configs.baseURL}/b4u-core/shop/add`, shopData)
  return res
}

export async function deleteEmployee(employeeId) {
  var data = {
    "employeeId": employeeId,
    "status": "Deleted"
  }
  const config = {
    headers: {
      Authorization: window.localStorage.getItem('loginToken'),
    }
  }

  const res = await axios.put(`${configs.baseURL}/b4u-core/employee/delete`, data, config)
  return res
}



export async function forgetPassword(email) {
  var data = {
    "siteName": configs.deployementName,
    "email": email,
    "portal": "Admin"
  };

  const res = await axios.put(`${configs.baseURL}/b4u-core/user/forgot`, data)
  return res
}

export async function resetPassword(data) {
  const res = await axios.put(`${configs.baseURL}/b4u-core/user/reset/password`, data)
  return res
}

export async function validateResetPasswordLink(encryptedValue) {
  const res = await axios.get(`${configs.baseURL}/b4u-core/user/reset/check_link?encryptedValue=${encryptedValue}`)
  return res
}
