const Images = {
    logo: require('./logo.png'),
    bin: require('./bin.png'),
    edit: require('./edit.png'),
    close: require('./close.png'),
    add: require('./add.png'),
    camera: require('./camera.png'),
    clockArrow: require('./clockArrow.png'),
    addGrey: require('./add-grey.png'),
    addGreen: require('./add-green.png'),
    addNote: require('./add-note.png'),
    userGuide: require('./userGuide.png'),
    calendar: require('./calendar.png'),
    clock: require('./clock.png'),
    service: require('./service.png'),
    more: require('./more.png'),
    search: require('./search.png'),
    customer: require('./customer.svg'),
    allService: require('./service.svg'),
    employees: require('./employees.svg'),
    appointments: require('./appointments.svg'),
    logout: require('./logout.svg'),
    covid: require('./covid.png'),
    settings: require('./settings.png'),
    money: require('./money.png'),
    falicities: require('./facility.svg'),
    deleteIcon: require('./delete.png'),
    smartBookingLogo: require('./SmartBooking.png'),
    instagram: require('./instagram.png'),
    facebook: require('./facebook.png'),
    whatsapp:require('./whatsapp.png'),
    twitter:require('./twitter.png'),
    closeBlack:require('./closeBlack.png'),
    info: require('./info.png'),
    cashStack: require('./cash-stack.svg'),
    check: require('./check.svg'),
    closeRedPlain: require('./closeRedPlain.svg')
};

export default Images;
