import { createStore, applyMiddleware, compose } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import ReduxThunk from 'redux-thunk'
import firebase from "firebase/app";

import { configs } from './configs'
import rootReducer from '../reducers'


export const InitializeApp = () => {

  // configure firebase -------------
  firebase.initializeApp(configs.firebase);

  /* ------------- Redux Configuration ------------- */
  const middleware = []
  const enhancers = []

  /* ------------- ReduxThunk Middleware ------------- */
  middleware.push(ReduxThunk)

  /* ------------- Redux Logger Middleware ------------- */
  // Note: logger must be the last middleware in chain, otherwise it will log
  // thunk and promise, not actual actions.

  function noop() { }
   
  let composeEnhancers ;
  if (process.env.NODE_ENV === 'development') {
    const { createLogger } = require('redux-logger')
    const logger = createLogger({ collapsed: true, duration: true, diff: true })
    middleware.push(logger)
    composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
  } else {
    console.log = noop;
    console.warn = noop;
    console.error = noop;
    composeEnhancers = compose
  }

  /* ------------- Assemble Middleware ------------- */
  const middlewareEnhancer = applyMiddleware(...middleware)
  enhancers.push(middlewareEnhancer)

  const persistConfig = {
    key: 'REDUX_OFFLINE_STORE',
    storage,
    whitelist: ['general', 'shop']
  }

  
  const persistedReducer = persistReducer(persistConfig, rootReducer)
  const store = createStore(persistedReducer, {}, composeEnhancers(...enhancers))
  const persistor = persistStore(store)

  return { store, persistor }
}
