import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import { logout } from '../utils/auth'
import { updateUser } from '../api/api'
import { getAppointments } from './'
import { fetchNotifications } from '../utils/Notification'
import { configs } from '../utils/configs'
import { uploadToStorage } from './../utils/storage'

import {
    CLEAR_STORE,
    FETCH_ADMIN_PROFILE_SUCCESS,
    // GET_SHOP_DATA_SUCCESS,

    UPDATE_USER,
    UPDATE_USER_SUCCESS,
    UPDATE_USER_ERROR,
    LOGIN,
    CHANGE_AUTH_STATE,
    SIGNUP
} from '../actions/types'

const IMAGE_FOLDER_NAME = 'admin_user'

export const signOut = (callBack) => {
    return async (dispatch) => {
        try {
            localStorage.clear();
            dispatch({ type: CLEAR_STORE })
            logout(callBack)

            await Notification.requestPermission()
            if (firebase.messaging.isSupported()) {
                const messaging = firebase.messaging();
                const token = await messaging.getToken();

                // removing fcm token to not receive push notifications
                await messaging.deleteToken(token);
            }
            else {
                throw new Error()
            }
        }
        catch (err) {
            console.log(err)
            dispatch({ type: CLEAR_STORE })
            logout(callBack)
        }
    }
}

export const logIn = (user, isLoggedIn) => {
    return async (dispatch) => {
        if (user) {
            dispatch({ type: LOGIN, payload: { user } })
            dispatch({ type: CHANGE_AUTH_STATE, payload: { isLoggedIn } })
        }
    }
}

export const signupData = (shopData, isLoggedIn) => {
    return async (dispatch) => {
        if (shopData) {
            console.log(shopData)
            dispatch({ type: SIGNUP, payload: { shopData } })
            dispatch({ type: CHANGE_AUTH_STATE, payload: { isLoggedIn } })
        }
    }
}

export const changeAuthState = (isLoggedIn) => {
    return (dispatch) => {
        console.log("isLoggedIn", isLoggedIn)
        dispatch({ type: CHANGE_AUTH_STATE, payload: { isLoggedIn } })
    }
}

export const initializeNotification = (user) => {
    return async (dispatch) => {
        fetchNotifications(user, dispatch)
    }
}

export const updateUserProfileImage = (shopSlug, uid, updatedData, originalData, callBack) => {
    return async (dispatch) => {
        dispatch({ type: UPDATE_USER })

        try {
            // Upload image
            if (updatedData.image) {
                let promiseArray = []

                let suffix = "_a" + (new Date()).getTime().toString()
                let storageUploadPromise = uploadToStorage(updatedData.image, IMAGE_FOLDER_NAME, shopSlug, uid, suffix)
                promiseArray.push(storageUploadPromise)

                // delete old image
                if (originalData.imageRef) {
                    let storageDeletePromise = firebase.storage().ref().child(originalData.imageRef).delete()
                    promiseArray.push(storageDeletePromise)
                }

                let response = await Promise.all(promiseArray)
                let { imageRef, url } = response[0]

                updatedData.image = url
                updatedData.imageRef = imageRef
            }

            let success = await updateUser(uid, updatedData)
            if (success) {
                dispatch({ type: UPDATE_USER_SUCCESS, payload: { updatedData } })
                callBack(true)
            } else {
                callBack(false)
                throw new Error()
            }
        } catch (error) {
            console.log(error)
            callBack(false)
        }
    }
}


export const updateUserDetails = (uid, updatedData, callBack) => {
    return async (dispatch) => {
        dispatch({ type: UPDATE_USER })

        try {
            let success = await updateUser(uid, updatedData)
            if (success) {
                dispatch({ type: UPDATE_USER_SUCCESS, payload: { updatedData } })
                callBack(true)
            } else {
                callBack(false)
                throw new Error()
            }
        } catch (error) {
            console.log(error)
            dispatch({ type: UPDATE_USER_ERROR })
            callBack(false)
        }
    }
}
