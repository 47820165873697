import React from 'react'
import './infoTooltip.css'

function InfoTooltip({image, text, className}) {
  return (
    <div className={className}>
        <img src={image} className="info-icon" height={27} alt="info" />
        <span class="hoverText">{text}</span>
    </div>
  )
}

export default InfoTooltip