import {
    CLEAR_STORE,

    GET_INITIAL_ANALYTICS,
    GET_INITIAL_ANALYTICS_SUCCESS,
    GET_INITIAL_ANALYTICS_ERROR,

    GET_GRAPH_DATA,
    GET_GRAPH_DATA_SUCCESS,
    GET_GRAPH_DATA_ERROR
} from "../actions/types";


const INITIAL_STATE = {
    thisYearAppointments: [],
    thisMonthAppointments: [],
    thisWeekAppointments: [],
    todayAppointments: [],
    AllAppointments: [],

    graphAppointments: [],

    // for dashboard
    upcomingAppointments: [],
    completedAppointments: [],
    cancelledAppointments: [],


    // Loading states
    isFetchingAnalytics: false,
    isFetchingGraphData: false,

    //New
    monthNoOfAppoinment: 0,
    todayNoOfAppoinment: 0,
    weekNoOfAppoinment: 0,
    yearNoOfAppoinment: 0,

    popularEmployeeArr: [],
    popularFacilityArr: [],
    popularServiceArr: [],
    serviceEmpFacArr: [],
}


export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CLEAR_STORE:
            return {
                ...INITIAL_STATE
            }
        case GET_INITIAL_ANALYTICS:
            return {
                ...state,
                isFetchingAnalytics: true
            }
        case GET_INITIAL_ANALYTICS_SUCCESS:
            return {
                ...state,
                ...action.payload.analyticsData,
                isFetchingAnalytics: false
            }
        case GET_INITIAL_ANALYTICS_ERROR:
            return {
                ...state,
                isFetchingAnalytics: false
            }
        case GET_GRAPH_DATA:
            return {
                ...state,
                isFetchingGraphData: true
            }
        case GET_GRAPH_DATA_SUCCESS:
            return {
                ...state,
                ...action.payload.graphData,
                isFetchingGraphData: false
            }
        case GET_GRAPH_DATA_ERROR:
            return {
                ...state,
                isFetchingGraphData: false
            }
        default:
            return state
    }
}
